import { ApiQueryService } from '@/services/ApiQueryService'
import { PipelineStoreState, RootState } from '@/types/StoreTypes'
import { PipelineRun } from '@/types/Types'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

const namespaced = true

const state: PipelineStoreState = {
  runningPipelines: [],
  isPolling: false,
  pipelineID: '',
  pipelineRunning: false,
  pipelineProgress: 0,
  pipelineMessage: '',
  pipelineError: '',
  pipelineSuccess: false,
  pipelineType: ''
}

const mutations: MutationTree<PipelineStoreState> = {
  addRunningPipeline (state, payload: PipelineRun) {
    state.runningPipelines.push(payload)
  },
  deleteRunningPipeline (state, payload: string) {
    const index = state.runningPipelines.findIndex(pipeline => pipeline.name === payload)
    if (index !== -1) {
      state.runningPipelines.splice(index, 1)
    }
  },
  setRunningPipeline (state, payload: Array<PipelineRun>) {
    state.runningPipelines = payload
  },
  setIsPolling (state, payload: boolean) {
    state.isPolling = payload
  },
  setPipelineID (state, payload: string) {
    state.pipelineID = payload
  },
  setPipelineRunning (state, payload: boolean) {
    state.pipelineRunning = payload
  },
  setPipelineProgress (state, payload: number) {
    state.pipelineProgress = payload
  },
  setPipelineMessage (state, payload: string) {
    state.pipelineMessage = payload
  },
  setPipelineError (state, payload: string) {
    state.pipelineError = payload
  },
  setPipelineSuccess (state, payload: boolean) {
    state.pipelineSuccess = payload
  },
  setPipelineType (state, payload: string) {
    state.pipelineType = payload
  }
}

const actions: ActionTree<PipelineStoreState, RootState> = {
  updateRunningPipelines ({ state }) {
    return new Promise<void>((resolve) => {
      state.runningPipelines = []
      ApiQueryService.checkAllPipelineStatus()
        .then((response: PipelineRun[]) => {
          // console.log('Pipelines:', response)
          for (const run of response) {
            run.pipeline = run.pipeline.substring(run.pipeline.lastIndexOf('-') + 1)
            if (run.status === 'ACTIVE') {
              if (!state.runningPipelines.some(pipeline => pipeline.name === run.name)) {
                state.runningPipelines.push(run)
              }
            }
          }
          // console.log('Running pipelines:', state.runningPipelines)
          resolve()
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }
}

const getters: GetterTree<PipelineStoreState, RootState> = {}

export const PipelineStore: Module<PipelineStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
