<template>
  <div>
    <v-app-bar class="app-bar" fixed clipped-left app :elevation="0">
      <v-toolbar-title style="cursor: pointer">
        <a @click="reload"><img src="../assets/traitology-soyverse.svg" class="logo"></a>
      </v-toolbar-title>
      <div class="by-pantograph">
        <span> powered by </span>
        <img src="../assets/pg_logo.svg" alt="Computomics' Pantograph" class="logo pantograph">
      </div>
      <v-spacer></v-spacer>
      <!-- Pipelines -->
      <v-col class="shrink" v-if="$route.name !== 'Pipelines'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="gotToPipelines" >
              <v-icon>mdi-cog-play-outline</v-icon>
            </v-btn>
          </template>
          <span>{{$t('appbar.pipelines')}}</span>
        </v-tooltip>
      </v-col>
      <!-- SnapshotMenu -->
      <SnapshotMenu />
      <!-- Legend -->
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="toggleLegend" >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{$t('appbar.legend')}}</span>
        </v-tooltip>
      </v-col>
      <!-- Documentation -->
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="goToHelp" >
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span> {{$t('appbar.show-documentation')}} </span>
        </v-tooltip>
      </v-col>
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import SnapshotMenu from '@/components/SnapshotMenu.vue'
import store from '@/store'

@Component({
  components: {
    SnapshotMenu
  }
})
export default class AppBar extends Vue {
  // ------------------------------------------------
  // Pipelines
  // ------------------------------------------------
  gotToPipelines () {
    this.$router.push({ name: 'Pipelines' })
  }

  // ------------------------------------------------
  // Reloading
  // ------------------------------------------------
  reload () {
    store.commit('chunkStore/setDataset', null)
    store.commit('pantoStore/setDatasetLoaded', false)
    // console.log('isAuth', store.getters['pantoStore/isAuthenticated'], store.state.pantoStore.authenticated)
    this.$router.push({ name: 'Genome' })
    // Clear query parameters from URL
    // history.replaceState(null, '', window.location.pathname)
    // Reload page
    // window.location.reload()
  }

  // ------------------------------------------------
  // Legend
  // ------------------------------------------------
  toggleLegend () {
    this.$store.commit('pantoStore/toggleLegend')
  }

  // ------------------------------------------------
  // Documentation
  // ------------------------------------------------
  get platformHelpUrl () {
    return process.env.VUE_APP_HELP_URL
  }

  goToHelp () {
    window.open(this.platformHelpUrl) // , '_blank')
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #e3e3e3;
}

.logo {
  height: 30px;
  margin-top: 10px;

  &.pantograph {
    height: 25px;
  }
}

.by-pantograph {
  margin-left: 20px;
  text-align: left;
  font-size: 0.8rem;

  span {
    position: relative;
    top: -7px;
  }
}
</style>
