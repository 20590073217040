<template>
  <div id="genome">
    <!-- Loader -->
    <Loader />
    <!-- Tooltip -->
    <Tooltip :tooltipText="tooltipText" :showTooltip="showTooltip">
      <div class="tooltip-content">
        {{ tooltipText }}
      </div>
    </Tooltip>
    <!-- Sidebar -->
    <div class="sidebar">
      <v-toolbar-title style="cursor: pointer">
        <a @click="reloadPage"><img src="../assets/traitology-soyverse.svg" class="logo"></a>
      </v-toolbar-title>
      <div class="by-pantograph">
        <span> powered by </span>
        <img src="../assets/pg_logo.svg" alt="Computomics' Pantograph" class="pantograph">
      </div>
      <hr class="small">
      <!-- QTL -->
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header color="#f2f2f2"><v-icon class="panel-header-icon" small>mdi-poll</v-icon><div class="panel-header-title">QTL</div></v-expansion-panel-header>
          <v-expansion-panel-content color="#f2f2f2">
            <div class="inner">
              <!-- Search by name -->
              <v-autocomplete
                :items="qtlTraits"
                item-text="trait"
                item-value="trait"
                clearable
                outlined
                dense
                autofocus
                color="#000000de"
                label="Add by name"
                @change="addQTLToPool"
              ></v-autocomplete>
              <v-alert
                v-if="Object.keys(selectedTraits).length >= maxQTLsToShow"
                type="info"
                dense
                text
              >
                You've reached the maximum of traits to show.
              </v-alert>
              <div>
                <!-- Hide gen markers -->
                <v-checkbox
                  v-model="areGenMarkedQTLsHidden"
                  label="Hide QTLs based on genetic markers"
                  color="#000000de"
                  dense
                  @change="filterQTLs"
                ></v-checkbox>
                <!-- Filter by length -->
                <div>
                  <label class="panel-content-title">Filter by length</label>
                  <v-slider
                    v-model="lengthFilter"
                    :max="maxQTLLength"
                    :min="minQTLLength"
                    step="1"
                    color="#000000de"
                    track-color="#000000de"
                    thumb-color="#000000de"
                    thumb-size="10"
                    @input="filterQTLs"
                  >
                    <template v-slot:thumb-label="{ value }">
                      <!-- {{ value <= minQTLLength ? '0M' : `${(value / 1000).toFixed(0)}k` }} -->
                      {{ `${(value / 1000).toFixed(0)}k` }}
                    </template>
                  </v-slider>
                </div>
              </div>
              <!-- QTL Chips -->
              <div
                v-if="selectedQTLs.length > 0 || Object.keys(selectedTraits).length > 0"
                class="mb-2"
              >
                <QTLChip
                  v-for="trait in Object.keys(selectedTraits)"
                  :key="trait"
                  :trait="trait"
                  :color="selectedTraits[trait]"
                  :active="highlightedTraits.includes(trait)"
                  @mouseover="hoveredQTL = trait"
                  @mouseout="hoveredQTL = ''"
                  @click="highlightQTL(trait)"
                />
              </div>
              <!-- Clear QTLs -->
              <v-btn
                v-if="selectedQTLs.length > 0 || Object.keys(selectedTraits).length > 0"
                class="mb-4"
                elevation="0"
                outlined
                block
                @click="clearQTLs"
              >{{ highlightedTraits.length === 0 ? 'Clear all QTLs' : highlightedTraits.length === 1 ? 'Remove selected QTL' : 'Remove selected QTLs' }}</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Genes -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#f2f2f2">
            <v-icon class="panel-header-icon" small>mdi-distribute-vertical-center</v-icon>
            <div class="panel-header-title">Genes</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#f2f2f2">
            <div class="inner">
              <v-form @submit="jumpToGene" onSubmit="return false;">
                <template>
                  <v-autocomplete
                    class="mb-2"
                    outlined
                    dense
                    required
                    clearable
                    :items="geneList"
                    :search-input.sync="searchStr"
                    :label="$t('jump-menu.gene')"
                    placeholder="Gene name"
                    v-model="targetGene"
                    :loading="loading"
                  >
                    <template v-slot:append-outer>
                      <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                        size="24"
                      ></v-progress-circular>
                    </template>
                  </v-autocomplete>
                </template>
                <v-btn elevation="0" outlined block type="submit">Navigate to gene</v-btn>
              </v-form>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- Chroms -->
    <div id="chromosomes-container" ref="chromosomesContainer">
      <h1>Genome View <span class="genome-name"></span></h1>
      <svg :height="svgHeight">
        <g
          v-for="(chromosome, index) in chromosomes"
          :key="chromosome.shortName"
          class="chromosome-group"
          :transform="`translate(${(index % 4) * xStep}, ${Math.floor(index / 4) * (maxChromHeight + chromYOffset) + svgYOffset})`">
          <text y="-10">
            {{ chromosome.shortName }}
          </text>
          <rect
            class="chromosome"
            :width="chromWidth" :height="getChromHeight(chromosome)"
            @click="jumpToGraph(chromosome)"
            @mouseover="tooltipText = 'Jump to ' + chromosome.shortName; showTooltip = true"
            @mouseleave="showTooltip = false"
          />
          <rect
            v-for="(qtlStrip, index) in getQTLStrips(chromosome)"
            :key="index"
            ref="qtlStrip"
            :x="chromWidth + qtlStripMargin + (trackNameToColumnIndex?.get(qtlStrip.trait) ?? 0) * (qtlStripWidth + qtlStripMargin)"
            :y="getQTLStripPosition(qtlStrip, chromosome)"
            :height="getQTLStripHeight(qtlStrip, chromosome)"
            :width="qtlStripWidth"
            :fill="qtlStrip.color"
            :class="{highlighted: qtlStrip.trait === hoveredQTL || highlightedTraits.includes(qtlStrip.trait)}"
            @click="jumpToGraph(chromosome, qtlStrip.trait)"
            @mouseover="tooltipText = qtlStrip.trait; showTooltip = true"
            @mouseleave="showTooltip = false"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Chromosome, Genome } from '@/types/Types'
import { GenomeService } from '@/services/GenomeService'
import QTLChip from '@/components/QTLChip.vue'
import Tooltip from '@/components/Tooltip.vue'
import { ApiQueryService } from '@/services/ApiQueryService'
import { GeneService } from '@/services/GeneService'
import GeneralUtils from '@/utils/GeneralUtils'
import Loader from '@/components/Loader.vue'
import PipelinePoller from '@/services/PipelinePoller'

export interface Trait {
  name: string
  color: string
}

@Component({
  components: {
    QTLChip,
    Tooltip,
    Loader
  }
})
export default class GenomeView extends Vue {
  chromosomes: Array<Chromosome> = []
  cachedChromosomes: Array<Chromosome> = []
  chromWidth = 18
  maxChromHeight = 400
  chromYOffset = 40 // vertical space between chromz
  svgYOffset = 30 // to see the first line of chrom names
  svgMargin = 16
  xStep = 0
  qtlStripWidth = 8
  qtlStripMargin = 6
  maxQTLsToShow = 12 // max number of QTLs to show (both as qtl chips and on chromosomes)
  qtlTraits: string[] = []
  qtls: any[] = []
  panel = [0, 1]
  tooltipText = ''
  showTooltip = false
  hoveredQTL = ''
  searchStr = ''
  geneList: string[] = []
  targetGene = ''
  loading = false

  get areGenMarkedQTLsHidden () {
    return this.$store.state.genomeStore.areGenMarkedQTLsHidden
  }

  set areGenMarkedQTLsHidden (value) {
    this.$store.commit('genomeStore/setAreGenMarkedQTLsHidden', value)
  }

  get minQTLLength () {
    return this.$store.state.genomeStore.minQTLLength
  }

  get lengthFilter () {
    return this.$store.state.genomeStore.lengthFilter
  }

  set lengthFilter (value) {
    this.$store.commit('genomeStore/setLengthFilter', value)
  }

  get selectedQTLs () {
    return this.$store.state.genomeStore.selectedQTLs
  }

  set selectedQTLs (value) {
    this.$store.commit('genomeStore/setSelectedQTLs', value)
  }

  get selectedTraits () {
    return this.$store.state.genomeStore.selectedTraits
  }

  set selectedTraits (value) {
    this.$store.commit('genomeStore/setSelectedTraits', value)
  }

  get highlightedTraits () {
    return this.$store.state.genomeStore.highlightedTraits
  }

  set highlightedTraits (value) {
    this.$store.commit('genomeStore/setHighlightedTraits', value)
  }

  get filteredQTLIDs () {
    return this.$store.state.genomeStore.filteredQTLIDs
  }

  set filteredQTLIDs (value) {
    this.$store.commit('genomeStore/setFilteredQTLIDs', value)
  }

  get maxQTLLength () {
    return this.$store.state.genomeStore.maxQTLLength
  }

  get trackNameToColumnIndex () {
    const trackNames = [...new Set(this.selectedQTLs.map((qtl: { trait: string }) => qtl.trait))]
    return new Map(trackNames.map((trackName, index) => [trackName, index]))
  }

  get svgHeight () {
    const rows = Math.ceil(this.chromosomes.length / 4)
    return rows * (this.maxChromHeight + this.chromYOffset)
  }

  get selectedDataset () {
    return this.$store.state.chunkStore.dataset
  }

  set selectedDataset (value) {
    this.$store.commit('chunkStore/setDataset', value)
  }

  get binWidth () {
    return this.$store.state.chunkStore.binWidth
  }

  set binWidth (value) {
    this.$store.commit('chunkStore/setBinWidth', value)
  }

  get enabledQTLTracks () {
    return this.$store.state.pantoStore.enabledQTLTracks
  }

  set enabledQTLTracks (value) {
    this.$store.commit('pantoStore/setEnabledQTLTracks', value)
  }

  @Watch('searchStr')
  onSearchStrChange () {
    this.debouncedGetGenes()
  }

  async getGenes () {
    this.loading = true
    await ApiQueryService.getGeneList(1, this.searchStr).then((res) => {
      this.loading = false
      this.geneList = res
    })
  }

  debouncedGetGenes = GeneralUtils.debounce(() => this.getGenes(), 800)

  reloadPage () {
    window.location.reload()
  }

  created () {
    // Fetch genome data only if it hasn't been fetched yet
    if (!this.$store.state.genomeStore.genome) {
      this.$store.commit('graphStore/setLoading', true)
      GenomeService.getGenome().then((genome: Genome) => {
        this.$store.commit('graphStore/setLoading', false)
        this.$store.commit('genomeStore/setGenome', genome)
        this.init(genome)
      })
    } else {
      this.init(this.$store.state.genomeStore.genome)
    }
  }

  mounted () {
    PipelinePoller.poll(20000)
  }

  init (genome: Genome) {
    // Set genome data
    this.setGenomeData(genome)
    // Filter QTLs based on length and gen markers
    this.lengthFilter = this.$store.state.genomeStore.lengthFilter
    this.areGenMarkedQTLsHidden = this.$store.state.genomeStore.areGenMarkedQTLsHidden
    this.filterQTLs()
    // Set xStep after the DOM has been rendered
    this.$nextTick(() => {
      const container = this.$refs.chromosomesContainer as HTMLElement
      this.xStep = (container?.clientWidth / 4) - (this.chromWidth / 4) - this.svgMargin
    })
  }

  setGenomeData (genome: Genome) {
    this.chromosomes = genome.chromosomes
    this.cachedChromosomes = genome.chromosomes
    this.qtlTraits = genome.uniqueTraits
    this.qtls = genome.qtls
  }

  getQTLStrips (chromosome: Chromosome) {
    return chromosome.qtls.filter(qtlStrip => this.selectedQTLs.some((qtl: any) => qtl.trait === qtlStrip.trait))
  }

  getChromHeight (chromosome: Chromosome) {
    const maxLength = Math.max(...this.chromosomes.map(chrom => chrom.length))
    return (chromosome.length / maxLength) * this.maxChromHeight
  }

  getQTLStripHeight (qtlStrip: any, chromosome: Chromosome) {
    const chromHeight = this.getChromHeight(chromosome)
    const qtlStripHeight = Math.max(this.minQTLLength, qtlStrip.end - qtlStrip.start) // we set a minimum length for 0 length QTLs
    return (qtlStripHeight / chromosome.length) * chromHeight
  }

  getQTLStripPosition (qtlStrip: any, chromosome: Chromosome) {
    const chromHeight = this.getChromHeight(chromosome)
    return (qtlStrip.start / chromosome.length) * chromHeight
  }

  highlightQTL (trait: string) {
    if (this.highlightedTraits.includes(trait)) {
      this.highlightedTraits = this.highlightedTraits.filter((qtl: any) => qtl !== trait)
    } else {
      this.highlightedTraits.push(trait)
    }
  }

  clearQTLs () {
    if (this.highlightedTraits.length) {
      this.selectedQTLs = this.selectedQTLs.filter((qtl: any) => !this.highlightedTraits.includes(qtl.trait))
      this.highlightedTraits.forEach((trait: string) => delete this.selectedTraits[trait])
      this.highlightedTraits = []
    } else {
      this.selectedQTLs = []
      this.selectedTraits = {}
      this.highlightedTraits = []
    }
  }

  addQTLToPool (trait: string) {
    if (trait) {
      // Use this.chromosomes since this is already filtered
      const qtlsForTrait = this.chromosomes.flatMap(chromosome => chromosome.qtls.filter(qtl => qtl.trait === trait))
      if (qtlsForTrait.length && Object.keys(this.selectedTraits).length < this.maxQTLsToShow) {
        qtlsForTrait.forEach((qtl: any) => this.selectedQTLs.push(qtl))
        this.selectedTraits[trait] = qtlsForTrait[0].color
      }
    }
  }

  filterQTLs () {
    this.chromosomes = this.cachedChromosomes.map(chromosome => {
      return {
        ...chromosome,
        qtls: chromosome.qtls.filter(qtl => {
          const isLengthValid = qtl.end - qtl.start <= this.lengthFilter
          const isGenMarkerValid = this.areGenMarkedQTLsHidden ? qtl.Start_cM === undefined : true
          return isLengthValid && isGenMarkerValid
        })
      }
    })
  }

  jumpToGraph (chromosome: Chromosome, trait?: any, binW = 100000) {
    this.selectedDataset = chromosome.shortName
    // Set bin width to 100kb in order to see the whole chromosome by default
    this.binWidth = binW
    // If the user clicks on a 'QTL strip', we only show the (filtered) QTLs of the clicked trait.
    // Otherwise, we show the (filtered) QTLs of all selected traits on the chromosome
    let qtlsForChromosome
    if (trait) {
      qtlsForChromosome = chromosome.qtls.filter(qtl => qtl.trait === trait && qtl.ref_path === chromosome.name)
    } else {
      qtlsForChromosome = chromosome.qtls.filter(qtl => qtl.trait in this.selectedTraits && qtl.ref_path === chromosome.name)
    }
    this.$store.commit('pantoStore/setEnabledQTLTracks', qtlsForChromosome)
    // Navigate to the graph view
    this.$router.push({ name: 'Graph', params: { dataset: this.selectedDataset } })
  }

  jumpToGene () {
    GeneService.getGenePassport(this.targetGene)
      .then((genePassport) => {
        this.$store.commit('graphStore/setLoading', true)
        this.selectedDataset = genePassport.dataset_name

        // retrieve the Chromosome fitting to targetGene
        const chromosomeOfGene = this.chromosomes.find(chromosome => chromosome.shortName === this.selectedDataset)
        if (chromosomeOfGene !== undefined) {
          // get selected QTLs of the chromosome
          const qtlsForChromosome = chromosomeOfGene.qtls.filter(qtl => qtl.trait in this.selectedTraits && qtl.ref_path === chromosomeOfGene.name)
          console.log('selected traits', this.selectedTraits, 'qtl for chromosome', qtlsForChromosome)
          this.$store.commit('pantoStore/setEnabledQTLTracks', qtlsForChromosome)

          // show the gene at default bin width 100
          this.binWidth = 100

          // get start bin of gene from API
          let startBin = -1
          ApiQueryService.getPanPosOfGene(this.binWidth, this.targetGene)
            .then((result) => {
              startBin = result.pan_pos
              console.log('jump to', this.targetGene, 'startBin', startBin, 'ROUTER', this.$router, 'selectedDataset', this.selectedDataset)
              if (startBin !== -1) {
                // let's jump to the graph view
                this.$router.push({ name: 'Graph', params: { dataset: this.selectedDataset, startBin: `${startBin}` } })
              }
              this.$store.commit('graphStore/setLoading', false)
            })
            .catch((error) => {
              this.$store.commit('graphStore/setLoading', false)
              if (error.response) {
                console.warn(error.response.data)
              }
            })
        } else {
          this.$store.commit('graphStore/setLoading', false)
          console.warn('Chromosome of gene', this.targetGene, 'not found')
        }
      })
      .catch((error) => {
        this.$store.commit('graphStore/setLoading', false)
        console.warn(error, error.response)
      })
  }
}
</script>

<style lang="scss" scoped>
#genome {
  width: 100vw;
  height: 100vh;
  background-color: rgb(252, 252, 252)
}

#chromosomes-container {
  width: 76%;
  height: 100vh;
  float: right;
  padding: $space-m;
  overflow-y: scroll;

  svg {
    width: 100%;
  }

  rect {
    cursor: pointer;
  }
}

.chromosome {
  fill: #D9D9D9;
  stroke-width: 1;
}

.sidebar {
  width: 24%;
  height: 100vh;
  float: left;
  background-color: #f2f2f2;
  padding: $space-m;
  overflow-y: scroll;
}

.logo {
  height: 30px;
  margin-top: 10px;
  cursor: pointer;

  &.pantograph {
    height: 25px;
  }
}

.pantograph {
  height: 25px;
}

.by-pantograph {
  margin-left: 0px;
  text-align: left;
  font-size: 0.7rem;
  height: 25px;

  span {
    position: relative;
    top: -8px;
  }
}

h1 {
  font-size: 16px;
  margin: 0 0 $space-m 0;
}

.genome-name {
  font-weight: normal;
  font-style: italic;
}

hr {
  margin: 10px 0;
  width: 100%;
  border: 0;
  border-top: 1px solid #bebebe;

  &.small {
    width: 30px;
  }
}

.v-expansion-panel-header, .v-expansion-panel-header--active {
  min-height: fit-content !important;
  padding: $space-m 0;
}

.panel-header-icon {
  margin-right: $space-s;
  color: #000000de;
}

.panel-header-title {
  font-size: 16px;
  font-weight: bold;
}

.inner {
  margin: 0 -24px -32px;
}

.highlighted {
  stroke: #3b34ff;
  stroke-width: 1;
}
</style>
