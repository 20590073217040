<template>
  <v-alert
    class="copy-alert"
    prominent
    :color="state.type === 'info' ? 'light-blue' : state.type"
    v-if="state.enabled"
    :type="state.type"
    :dismissible="state.dismissible"
  >
  <span v-html="state.message"></span>
  </v-alert>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Chip extends Vue {
  get state () {
    return this.$store.state.pantoStore.alertState
  }

  set state (value) {
    this.$store.commit('pantoStore/setAlert', value)
  }

  @Watch('state')
  onStateChange () {
    if (this.state.duration) {
      setTimeout(() => {
        this.state.enabled = false
      }, this.state.duration)
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-alert {
  position: fixed;
  bottom: 40px;
  left: 50%;
  z-index: 9999;
}
</style>
