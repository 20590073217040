import Papa from 'papaparse'

export interface ValidationResult {
  isValid: boolean
  errors: string[]
}

export class CSVValidator {
  private static readonly EXPECTED_COLUMNS = 3
  private static readonly EXPECTED_HEADER = ['vcf_group_name', 'reference_genome', 'vcf_file_path']

  public static validateVCFSamplesheet (file: File): Promise<ValidationResult> {
    return new Promise((resolve, reject) => {
      Papa.parse(file as any, {
        complete: (results: Papa.ParseResult<string[]>) => {
          const errors: string[] = []

          if (results.data.length < 2) { // At least header + one data row
            errors.push('The file must contain a header row and at least one data row')
          } else {
            // Validate header
            const header = results.data[0]
            if (!this.validateHeader(header, errors)) {
              resolve({ isValid: false, errors })
              return
            }

            // Validate data rows
            results.data.slice(1).forEach((row, index) => this.validateVCFRow(row, index + 1, errors))
          }

          resolve({
            isValid: errors.length === 0,
            errors
          })
        },
        error: (error: Papa.ParseError) => {
          reject(new Error(`Error parsing CSV file: ${error.message}`))
        }
      } as Papa.ParseConfig<string[]>)
    })
  }

  private static validateHeader (header: string[], errors: string[]): boolean {
    if (header.length !== this.EXPECTED_COLUMNS) {
      errors.push(`Header should have ${this.EXPECTED_COLUMNS} columns, found ${header.length}`)
      return false
    }
    if (!header.every((col, index) => col.toLowerCase() === this.EXPECTED_HEADER[index])) {
      errors.push(`Invalid header. Expected: ${this.EXPECTED_HEADER.join(', ')}`)
      return false
    }
    return true
  }

  private static validateVCFRow (row: string[], rowIndex: number, errors: string[]): void {
    if (row.length !== this.EXPECTED_COLUMNS) {
      errors.push(`Row ${rowIndex}: Expected ${this.EXPECTED_COLUMNS} columns, found ${row.length}`)
      return
    }

    if (row.some(field => field.trim() === '')) {
      errors.push(`Row ${rowIndex}: All fields must be non-empty`)
      return
    }

    const s3Regex = /^s3:\/\/.+\.vcf$/i
    if (!s3Regex.test(row[2])) {
      errors.push(`Row ${rowIndex}: Invalid S3 path for VCF file. Must start with 's3://' and end with '.vcf'`)
    }
  }
}
