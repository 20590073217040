<template>
  <div>
    <div class="header">
      <div class="title">{{$t('jump-menu.title')}}</div>
    </div>
    <div class="content">
      <v-expansion-panels v-model="panel" multiple focusable>
        <v-expansion-panel>
          <v-expansion-panel-header><div class="panel-title">Jump to path</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <v-form ref="pathForm" @submit="jumpToPath" lazy-validation onSubmit="return false;">
                <v-autocomplete
                  required
                  clearable
                  :rules="[rules.pathRequired]"
                  :items="paths"
                  v-model="targets.path.name"
                  :label="$t('jump-menu.path')"
                />
                <v-text-field
                  required
                  :rules="[rules.posRequired, rules.number, rules.greater0]"
                  :label="$t('jump-menu.position')"
                  v-model="targets.path.position"
                />
                <v-btn class="mt-4 mb-2" elevation="0" type="submit">Jump</v-btn>
              </v-form>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header><div class="panel-title">Jump to pangenome</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <v-form ref="pangenomeForm" @submit="jumpToPangenomePosition" lazy-validation onSubmit="return false;">
                <v-text-field
                    required
                    :rules="[rules.posRequired, rules.number, rules.greater0, rules.tooLarge]"
                    :label="$t('jump-menu.pangenome-position')"
                    v-model="targets.pangenome"
                  />
                  <v-btn class="mt-4 mb-2" elevation="0" type="submit">Jump</v-btn>
              </v-form>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header><div class="panel-title">Jump to gene</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <v-form @submit="jumpToGene" onSubmit="return false;">
                <template>
                  <v-autocomplete
                    required
                    clearable
                    :items="geneList"
                    :search-input.sync="searchStr"
                    :label="$t('jump-menu.gene')"
                    placeholder="Gene name"
                    v-model="targets.gene"
                    :loading="loading"
                  >
                    <template v-slot:append-outer>
                      <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                        size="24"
                      ></v-progress-circular>
                    </template>
                  </v-autocomplete>
                </template>
                <v-btn class="mt-4 mb-2" elevation="0" type="submit">Jump</v-btn>
              </v-form>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import { VForm } from '@/$refs'
import { ApiQueryService } from '@/services/ApiQueryService'
import { GeneService } from '@/services/GeneService'
import GeneralUtils from '@/utils/GeneralUtils'

@Component
export default class JumpMenu extends Vue {
  rules = {
    pathRequired: (v: string) => !!v || 'Path is required',
    posRequired: (v: string) => !!v || 'Position is required',
    required: (v: string) => !!v || 'Value required',
    number: (v: string) => /^\d*$/.test(v) || 'Position not a number',
    greater0: (v: string) => Number(v) > 0 || 'Position must be > 0',
    greatereq0: (v: string) => Number(v) >= 0 || 'Fraction must be >= 0',
    smaller1: (v: string) => Number(v) <= 1 || 'Fraction must be <= 1',
    tooLarge: (v: string) => Number(v) <= this.currentMaxBin * this.selectedBinWidth || 'Position too large'
  }

  targets = {
    path: { name: '', position: '' },
    pangenome: '',
    gene: ''
  }

  panel = [0, 1, 2]

  searchStr = ''
  geneList: string[] = []
  loading = false

  async getGenes () {
    this.loading = true
    await ApiQueryService.getGeneList(this.$store.state.chunkStore.binWidth, this.searchStr).then((res) => {
      this.loading = false
      this.geneList = res
    }).catch((error) => {
      console.warn(error)
    })
  }

  get selectedBinWidth () {
    return this.$store.state.chunkStore.binWidth
  }

  get currentMaxBin (): number {
    return this.$store.state.chunkStore.currentMaxBin
  }

  get paths () {
    return [...this.$store.state.chunkStore.trackMap.keys()]
  }

  @Watch('searchStr')
  onSearchStrChange () {
    this.debouncedGetGenes()
  }

  debouncedGetGenes = GeneralUtils.debounce(() => this.getGenes(), 800)

  jumpToPath () {
    const pathForm = this.$refs.pathForm as VForm
    if (pathForm.validate()) {
      this.$store.dispatch('pantoStore/jumpToPath', this.targets.path)
    }
  }

  jumpToPangenomePosition () {
    const pangenomeForm = this.$refs.pangenomeForm as VForm
    if (pangenomeForm.validate()) {
      this.$store.dispatch('pantoStore/jumpToPangenomePosition', this.targets.pangenome)
    }
  }

  jumpToGene () {
    this.$store.commit('graphStore/setLoading', true)
    GeneService.getGenePassport(this.targets.gene)
      .then((genePassport) => {
        const dataset = genePassport.dataset_name

        if (this.$store.state.chunkStore.dataset === dataset) {
          // just jump to gene on same dataset
          this.$store.dispatch('pantoStore/jumpToGene', this.targets.gene)
          return
        } // else: call load new dataset and jump to gene

        // use default bin width 100 unless user is further zoomed in
        const binWidth = this.$store.state.chunkStore.binWidth > 100 ? 100 : this.$store.state.chunkStore.binWidth

        // get start bin of gene from API
        let startBin = -1
        ApiQueryService.getPanPosOfGene(binWidth, this.targets.gene)
          .then((result) => {
            startBin = result.pan_pos
            console.log('jump to', this.targets.gene, 'startBin', startBin, 'ROUTER', this.$router, 'dataset', dataset)
            if (startBin !== -1) {
              this.$store.commit('chunkStore/setDataset', dataset)
              this.$store.commit('chunkStore/setBinWidth', binWidth)
              this.$store.commit('chunkStore/setDefaultBin', startBin)
              // a Watcher in Graph.vue is listening for changes of 'dataset' and takes over from here
            } else {
              this.$store.commit('graphStore/setLoading', false)
            }
          })
          .catch((error) => {
            this.$store.commit('graphStore/setLoading', false)
            if (error.response) {
              console.warn(error.response.data)
            }
          })
      })
      .catch((error) => {
        this.$store.commit('graphStore/setLoading', false)
        console.warn(error, error.response)
      })
  }
}
</script>
