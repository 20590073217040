<template>
  <div>
    <div class="header">
      <v-icon class="header-icon" small>mdi-dna</v-icon>
      <span class="header-title">{{$t('gene-menu.title')}}</span>
    </div>
    <div class="content">
      <!-- Summary -->
      <v-expansion-panels v-model="panel" multiple focusable>
        <v-expansion-panel>
          <v-expansion-panel-header><v-icon class="title-icon" small>mdi-clipboard-text-outline</v-icon><div class="panel-title">Summary</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <!-- Name -->
              <div class="panel-content-title">Name</div>
              <span class="panel-content">{{ selectedGene.id }}</span>
              <!-- Copy -->
              <div class="copy-container">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn x-small text class="btn-copy" v-on="on" @click="copyToClipboard('text', selectedGene.id)">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
              </div>
              <!-- Assembly -->
              <v-divider class="divider"></v-divider>
              <div class="panel-content-title">Assembly <span class="panel-content">{{ selectedGene.assembly }}</span></div>
              <!-- Position -->
              <div v-if="genePassport?.start">
                <v-divider class="divider"></v-divider>
                <div class="panel-content-title">Position <span class="panel-content">{{ genePassport?.dataset_name }}:{{ genePassport?.start }}..{{ genePassport?.stop }}</span></div>
                <!-- Length -->
                <v-divider class="divider"></v-divider>
                <div class="panel-content-title">Strand <span class="panel-content">{{ genePassport.strand ? 'forward' : 'reverse' }}</span></div>
                <!-- Length -->
                <v-divider class="divider"></v-divider>
                <div class="panel-content-title">Length <span class="panel-content">{{ numberWithCommas(geneLen) }}</span></div>
              </div>
              <!-- Functional Description -->
              <div v-if="genePassport?.descr">
                <v-divider class="divider"></v-divider>
                <div class="panel-content-title">Functional Description <span class="panel-content">{{ genePassport?.descr }}</span></div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Gene Functions -->
        <v-expansion-panel v-if="genePassport?.anno && Object.keys(genePassport?.anno).length">
          <v-expansion-panel-header><v-icon class="title-icon" small>mdi-blur-linear</v-icon><div class="panel-title">Gene Function</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <!-- Annotations -->
              <div v-if="genePassport?.anno.GO">
                <div class="panel-content-title">GO terms
                  <span v-for="(values, key) in genePassport?.anno.GO" :key="key" class="panel-content">
                    <span v-html="Array.isArray(values) ? values.map(value => `<a href='https://amigo.geneontology.org/amigo/search/ontology?q=${value}' target='_blank'>${value}</a>`).join(' ') : `<a href='https://amigo.geneontology.org/amigo/search/ontology?q=${values}' target='_blank'>${values}</a>`"></span>
                  </span>
                </div>
              </div>
                <div v-if="genePassport?.anno.InterPro">
                <v-divider class="divider" v-if="genePassport?.anno.GO"></v-divider>
                <div class="panel-content-title">InterPro
                  <span v-for="(values, key) in genePassport?.anno.InterPro" :key="key" class="panel-content">
                    <span v-html="Array(values).map(value => `<a href='https://www.ebi.ac.uk/interpro/entry/InterPro/${value}' target='_blank'>${value} </a>`).join(' ')"></span>
                  </span>
                </div>
              </div>
              <div v-for="(values, key) in genePassport?.anno" :key="key">
                <v-divider class="divider" v-if="key !== 'InterPro' && key !== 'GO'"></v-divider>
                <div class="panel-content-title" v-if="key !== 'InterPro' && key !== 'GO'">{{ key }}
                  <span class="panel-content">{{ values.join(', ') }}</span>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Sequences -->
        <v-expansion-panel>
          <v-expansion-panel-header><v-icon class="title-icon" small>mdi-dna</v-icon><div class="panel-title">Sequences</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <v-container>
                <v-row>
                  <v-col>
                    <!-- Gene Sequences -->
                    <div>
                      <div class="panel-content-title mb-2">Gene Sequence</div>
                      <v-btn class="mr-2" small elevation="none" @click="getGeneSeq()">
                        <v-icon left>mdi-eye-outline</v-icon>
                        View
                      </v-btn>
                      <v-btn class="mr-2" small elevation="none" @click="copyToClipboard('gene')">
                        <v-icon left>mdi-content-copy</v-icon>
                        Copy
                      </v-btn>
                      <v-btn small elevation="none" @click="downloadFastaFile('gene', selectedGene.id, selectedGene.id + '.fa')">
                        <v-icon left>mdi-download</v-icon>
                        Download Fasta
                      </v-btn>
                      <v-textarea v-if="geneSeq"
                        v-model="geneSeq"
                        :rows="3"
                        readonly
                        variant="filled"
                      ></v-textarea>
                    </div>
                  </v-col>
                </v-row>
                <div class="transcripts">
                  <div class="panel-content-title mb-2">Transcripts</div>
                  <v-row>
                    <v-col class="col-1" align="right">
                      <div class="transcripts-name" v-for="(name, index) in transcriptNames" :key="index">{{ name.split('.').slice(-1)[0] }}</div>
                    </v-col>
                    <v-col>
                      <svg
                        :width="transcriptsSVG.width"
                        :height="transcriptsSVG.height"
                        class="transcripts-svg"
                        v-for="(transcriptRanges, index) in filteredGeneTranscripts"
                        :key="index"
                      >
                        <line
                            :x1="0"
                            :x2="transcriptsSVG.width"
                            :y1="transcriptsSVG.height / 2"
                            :y2="transcriptsSVG.height / 2"
                            stroke="black"
                          />
                          <line
                            :x1="0"
                            :x2="0"
                            :y1="0"
                            :y2="transcriptsSVG.height"
                            stroke="black"
                          />
                          <line
                            :x1="transcriptsSVG.width"
                            :x2="transcriptsSVG.width"
                            :y1="0"
                            :y2="transcriptsSVG.height"
                            stroke="black"
                          />
                          <rect
                            v-for="(range, key) in transcriptRanges" :key="key"
                            :x="normalize(range[0], geneTranscripts.minimum, geneTranscripts.maximum, 0, transcriptsSVG.width)"
                            :y="transcriptsSVG.height / 4"
                            :width="normalize(range[1], geneTranscripts.minimum, geneTranscripts.maximum, 0, transcriptsSVG.width) - normalize(range[0], geneTranscripts.minimum, geneTranscripts.maximum, 0, transcriptsSVG.width)"
                            :height="transcriptsSVG.height / 2"
                            fill="#E35C4F"
                          />
                      </svg>
                    </v-col>
                    <v-col>
                      <div v-for="(name, index) in transcriptNames" :key="index">
                        <div class="transcripts-radio-group">
                          <v-radio-group v-model="transcriptTypes[name]" row @change="updateTranscriptSeq(name)">
                            <v-radio
                              label="Protein"
                              value="prot"
                            ></v-radio>
                            <v-radio
                              label="CDS"
                              value="cds"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                        <div class="transcripts-tool-group">
                          <v-btn class="mr-2" small elevation="none" @click="getTranscriptSeq(name)">
                            <v-icon small>mdi-eye-outline</v-icon>
                          </v-btn>
                          <v-btn class="mr-2" small elevation="none" @click="copyToClipboard(transcriptTypes[name], name)">
                            <v-icon small>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn small elevation="none" @click="downloadFastaFile(transcriptTypes[name], name, name + '.fa')">
                            <v-icon small>mdi-download</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-textarea v-if="transcriptSeq"
                      v-model="transcriptSeq"
                      :rows="3"
                      readonly
                      variant="filled"
                    ></v-textarea>
                  </v-row>
                </div>
              </v-container>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Homology -->
        <v-expansion-panel
          :disabled="!genePassport?.ortho_group || loadingHomology">
          <v-expansion-panel-header>
            <v-icon class="title-icon" small>mdi-text-long</v-icon>
            <div class="panel-title">Homology</div>
            <template>
              <v-progress-circular
                v-if="loadingHomology"
                indeterminate
                color="primary"
                size="24"
              ></v-progress-circular>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <div class="panel-content-title">Homology group <span class="panel-content">{{ genePassport?.ortho_group }}</span></div>
                <div v-if="orthoGroupCountImg">
                  <!-- Count Barplot -->
                  <v-divider class="divider"></v-divider>
                  <div class="panel-content-title">Homolog gene count</div>
                  <div class="zoom-btn-container">
                    <v-btn class="zoom-btn" outlined elevation="0" @click="homZoomIn">+</v-btn>
                    <v-btn class="zoom-btn" outlined elevation="0" @click="homZoomOut">-</v-btn>
                  </div>
                  <div class="homology-container">
                    <v-img
                      :src="barplotImg"
                      alt="Phylogenetic Tree of Homologous Genes"
                      :width="orthoBarplotWidth"
                      :max-width="maxOrthoBarplotWidth"
                      />
                  </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div v-for="(values, key) in orthoGroupMembers" :key="key">
                  <div class="panel-content-title">{{ key }}
                    <span class="panel-content">{{ values.join(', ') }}</span>
                  </div>
                </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Tree & MSA -->
        <v-expansion-panel
          :disabled="!orthoGroupTreeImg || loadingMSA">
          <v-expansion-panel-header>
            <v-icon class="title-icon" small>mdi-file-tree</v-icon>
            <div class="panel-title">Phylogenetic tree of homologous genes</div>
            <template>
            <v-progress-circular
              v-if="loadingMSA"
              indeterminate
              color="primary"
              size="24"
            ></v-progress-circular>
          </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
                <div>
                  <!-- MSA/Tree svg -->
                  <div class="zoom-btn-container">
                    <v-btn class="zoom-btn" outlined elevation="0" @click="treeZoomIn">+</v-btn>
                    <v-btn class="zoom-btn" outlined elevation="0" @click="treeZoomOut">-</v-btn>
                  </div>
                  <div class="homology-container">
                    <v-img
                      :src="treeImg"
                      alt="Phylogenetic Tree of Homologous Genes"
                      :width="treeWidth"
                      :max-width="maxTreeWidth"
                    />
                  </div>
                </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          :disabled="!hasExpr && !loadingExpr">
          <v-expansion-panel-header>
            <v-icon class="title-icon" small>mdi-dna</v-icon>
            <div class="panel-title">Gene Expression</div>
            <template>
              <v-progress-circular
                v-if="loadingExpr"
                indeterminate
                color="primary"
                size="24"
              ></v-progress-circular>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="inner">
              <v-btn outlined @click="openExpressionView">View Expression</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import { GeneService, GenePassport } from '@/services/GeneService'
import { ApiQueryService } from '@/services/ApiQueryService'
import PixiConfig from '@/graph/Config'
import GeneralUtils from '@/utils/GeneralUtils'

@Component
export default class GeneMenu extends Vue {
  genePassport: GenePassport | null = null
  geneLen: number | null = null
  geneSeq = ''
  transcriptSeq = ''
  transcriptTypes = {} as Record<string, string>
  protSeq = ''
  cdsSeq = ''

  orthoBarplotWidth = 1000
  maxOrthoBarplotWidth = 2000
  treeWidth = 2000
  maxTreeWidth = 3000

  orthoGroupMembers: Record<string, Array<string>> = {}
  orthoGroupCountImg = false
  orthoGroupTreeImg = false
  barplotImg = ''
  treeImg = ''
  hasExpr = false

  loadingHomology = true
  loadingMSA = true
  loadingExpr = true

  panel = [0]

  transcriptsSVG = {
    width: 400,
    height: 20
  }

  geneTranscripts: any = {}
  //   minimum: 27825,
  //   maximum: 28138,
  //   'Wm82a4.01G000100.1': [
  //     [27825, 27925],
  //     [27992, 28138]
  //   ],
  //   'Wm82a4.01G000100.2': [
  //     [27835, 27945]
  //   ]
  // }

  get transcriptNames (): Array<string> {
    return Object.keys(this.geneTranscripts).filter(key => key !== 'minimum' && key !== 'maximum')
  }

  get filteredGeneTranscripts (): Array<any> {
    const { minimum, maximum, ...filteredTranscripts } = this.geneTranscripts
    return Object.values(filteredTranscripts)
  }

  get selectedGene () {
    return this.$store.state.pantoStore.selectedGene
  }

  set selectedGene (gene: { id: string, assembly: string }) {
    this.$store.commit('pantoStore/setSelectedGene', gene)
  }

  @Watch('selectedGene')
  onSelectedGeneChanged () {
    this.update()
  }

  created () {
    // Initialize selectedGene if it is not set
    if (!this.selectedGene) {
      this.selectedGene = { id: '', assembly: '' }
    } else {
      this.update()
    }
  }

  numberWithCommas (n: number | null) {
    return n !== null ? GeneralUtils.numberWithCommas(n) : '-'
  }

  update () {
    if (this.selectedGene.id) {
      ApiQueryService.getGeneTranscripts(this.selectedGene.id)
        .then((geneTranscripts) => {
          this.geneTranscripts = geneTranscripts
          // Initialize all transcriptTypes
          this.transcriptNames.forEach(name => {
            this.transcriptTypes[name] = 'prot'
          })
        })
        .catch((error) => {
          this.geneTranscripts = {}
          console.log('error:', error)
        })
    }
    GeneService.getGenePassport(this.selectedGene.id)
      .then((genePassport) => {
        this.genePassport = genePassport
        this.geneLen = genePassport.stop - genePassport.start + 1

        if (this.genePassport?.ortho_group) {
          ApiQueryService.getOrthoGroupMembers(this.genePassport?.ortho_group)
            .then((members) => {
              this.orthoGroupMembers = members.data
              this.loadingHomology = false
            })
            .catch((error) => {
              console.error('error:', error)
              this.loadingHomology = false
            })

          ApiQueryService.getFile(PixiConfig.datasetFolderName + 'orthoGroups/barplots/' + this.genePassport?.ortho_group + '_count.svg')
            .then((svg) => {
              if (!svg) this.orthoGroupCountImg = false
              else {
                // const image = document.getElementById('orthoGroupCounts') as HTMLImageElement
                // // image.src = 'data:image/svg+xml;base64,' + svg

                const blob = new Blob([svg], { type: 'image/svg+xml;base64' })
                const url = URL.createObjectURL(blob)
                if (url !== null) {
                  this.barplotImg = url
                  // image.src = url
                  // image.addEventListener('load', () => URL.revokeObjectURL(url), { once: true })
                  this.orthoGroupCountImg = true
                } else {
                  this.orthoGroupCountImg = false
                }
              }
            })
            .catch((error) => {
              this.orthoGroupCountImg = false
              console.error('error:', error)
            })

          ApiQueryService.getImage(PixiConfig.datasetFolderName + 'orthoGroups/trees/' + this.genePassport?.ortho_group + '_tree.png')
            .then((png) => {
              if (!png) this.orthoGroupTreeImg = false
              else {
                this.orthoGroupTreeImg = true
                this.treeImg = png
              }
              this.loadingMSA = false
            })
            .catch((error) => {
              this.loadingMSA = false
              this.orthoGroupTreeImg = false
              console.error('error:', error)
            })
        } else {
          this.loadingHomology = false
          this.loadingMSA = false
        }

        // check if there is expression data for that gene
        ApiQueryService.getGeneExpression(this.selectedGene.id, 'stats').then((res) => {
          if (res.num_projects > 0) {
            this.hasExpr = true
          } else {
            this.hasExpr = false
          }
          this.loadingExpr = false
        }).catch((error) => {
          this.loadingExpr = false
          console.error(error)
        })
      }).catch(() => {
        this.genePassport = null
      })
  }

  normalize (value: number, min: number, max: number, newMin: number, newMax: number) {
    return GeneralUtils.normalize(value, min, max, newMin, newMax)
  }

  getGeneSeq () {
    return new Promise<string>((resolve) => {
      GeneService.getGeneSeq(this.selectedGene.id)
        .then((geneSeq: any) => {
          this.geneSeq = geneSeq[this.selectedGene.id]
          resolve(this.geneSeq)
        })
        .catch(() => {
          this.geneSeq = ''
        })
    })
  }

  getTranscriptSeq (name: string) {
    return new Promise<string>((resolve) => {
      ApiQueryService.getTranscriptSeq(name, this.transcriptTypes[name])
        .then((seq: any) => {
          this.transcriptSeq = seq
          resolve(this.transcriptSeq)
        })
        .catch(() => {
          this.transcriptSeq = ''
        })
    })
  }

  updateTranscriptSeq (name: string) {
    this.getTranscriptSeq(name)
  }

  async copyToClipboard (type: string, param = '') {
    let copyText = ''
    if (type === 'gene') {
      copyText = this.geneSeq
      if (copyText === '') {
        copyText = await this.getGeneSeq()
      }
    } else if (type === 'prot' || type === 'cds') {
      copyText = await this.getTranscriptSeq(param)
    } else {
      copyText = param
    }

    if (copyText !== '') {
      navigator.clipboard.writeText(copyText)
      this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Copied to clipboard', type: 'success', duration: 1000, dismissible: false })
    }
  }

  async downloadFastaFile (type: string, header: string, filename: string) {
    let seq = ''
    if (type === 'gene') {
      seq = this.geneSeq
      if (seq === '') {
        seq = await this.getGeneSeq()
      }
    } else if (type === 'prot' || type === 'cds') {
      seq = await this.getTranscriptSeq(header)
    }

    if (seq !== '') {
      const content = '>' + header + '\n' + seq
      const element = document.createElement('a')
      const file = new Blob([content], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = filename
      document.body.appendChild(element)
      element.click()
    }
  }

  openExpressionView () {
    this.$router.push({ path: `/expression/${this.selectedGene.id}` })
  }

  homZoomIn () {
    if (this.orthoBarplotWidth < this.maxOrthoBarplotWidth) {
      this.orthoBarplotWidth += 100
    }
  }

  homZoomOut () {
    if (this.orthoBarplotWidth > 500) {
      this.orthoBarplotWidth -= 100
    }
  }

  treeZoomIn () {
    if (this.treeWidth < this.maxTreeWidth) {
      this.treeWidth += 100
    }
  }

  treeZoomOut () {
    if (this.treeWidth > 500) {
      this.treeWidth -= 100
    }
  }
}
</script>

<style lang="scss" scoped>
.transcripts {
  margin-top: 18px;
}
.transcripts-name {
  margin: 4px 0 18px 0;
  font-size: 14px;
}
.transcripts-svg {
  margin: 4px 0 20px;
  display: block;
}
.transcripts-radio-group {
  display: inline-block;
  margin-top: -18px;
  margin-bottom: -5px;
}
.transcripts-tool-group {
  display: inline-block;
  margin-bottom: 10px;
  transform: translateY(-5px);
}

.copy-container {
  display: inline-block;
}

.homology-container {
  width: 100%;
  max-height: 1000px;
  overflow: scroll;
}

.zoom-btn-container {
  display: flex;
  justify-content: right;
  margin-bottom: 14px;

  .zoom-btn {
    margin-left: 5px !important;
  }
}
</style>
