<template>
  <div id="expression">
    <!-- Loader -->
    <Loader />
    <!-- App bar -->
    <AppBar />
    <!-- Content -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div class="text-subtitle-1">Gene expression for:</div>
      <div class="text-h4 mb-4">{{ geneID }}</div>
      <hr class="wide">
      <v-row class="mt-6">
        <v-col cols="6">
          <div class="text-h5 mb-4">Expression by Tissue</div>
          <div class="tissue-container">
            <div class="tissue-marker-container">
              <div id="leaves" ref="leaves" class="tissue-marker"></div>
              <div id="petiole" ref="petiole" class="tissue-marker"></div>
              <div id="flower" ref="flower" class="tissue-marker"></div>
              <div id="pod" ref="pod" class="tissue-marker"></div>
              <div id="suspensor" ref="suspensor" class="tissue-marker"></div>
              <div id="shoot" ref="shoot" class="tissue-marker"></div>
              <div id="nodules" ref="nodules" class="tissue-marker"></div>
              <div id="branch-roots" ref="root" class="tissue-marker"></div>
              <div id="embryo" ref="embryo" class="tissue-marker"></div>
              <div id="endosperm" ref="endosperm" class="tissue-marker"></div>
              <div id="seed-coat" ref="seed coat" class="tissue-marker"></div>
              <div id="seed" ref="seed" class="tissue-marker"></div>
              <div id="epicotyl" ref="epicotyl" class="tissue-marker"></div>
              <div id="cotyledon" ref="cotyledon" class="tissue-marker"></div>
              <div id="hypocotyl" ref="hypocotyl" class="tissue-marker"></div>
              <div id="radicle" ref="radicle" class="tissue-marker"></div>
              <div id="seedling" ref="seedling" class="tissue-marker"></div>
            </div>
            <img src="../assets/soy-tissues.svg">
          </div>
          <!-- Slider -->
          <div class="text-body-1 mt-4 mb-2">Min/Max TPM</div>
          <template>
            <v-range-slider
              :max="tissueMarkersRangeMax"
              :min="tissueMarkersRangeMin"
              step="1"
              v-model="tissueMarkersRange"
            >
              <template v-slot:prepend>
                <v-text-field
                  v-model="tissueMarkersRange[0]"
                  style="width: 70px; transform: translateY(-8px)"
                  type="number"
                  outlined
                  hide-details
                  single-line
                  dense
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="tissueMarkersRange[1]"
                  style="width: 70px; transform: translateY(-8px)"
                  type="number"
                  outlined
                  hide-details
                  single-line
                  dense
                ></v-text-field>
              </template>
            </v-range-slider>
          </template>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="tissueTableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="tissueTableHeaders"
            :items="tissueTableItems"
            :search="tissueTableSearch"
          ></v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11">
          <div class="text-h5 mt-4 mb-4">Expression by 1/2 Factor(s)</div>
          <div class="plot-container" ref="plotContainer"></div>
        </v-col>
        <v-col cols="1" class="heatmap-container">
          <svg id="heatmap"></svg>
          <div class="label-container">
            <div class="label high text-caption">{{ heatmapMaxTPM }}</div>
            <div class="label low text-caption">0</div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select
              style="width: 80%; margin: auto;"
              class="d-flex mt-12 mb-4"
              v-model="xFactor"
              :items="factorXItems"
              label="X"
              outlined
              dense
              @change="onFactorChange"
            ></v-select>
            <v-select
              style="width: 80%; margin: auto;"
              class="d-flex"
              v-model="yFactor"
              :items="factorYItems"
              label="Y"
              outlined
              dense
              @change="onFactorChange"
            ></v-select>
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="factorTableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="factorTableHeaders"
            :items="factorTableItems"
            :search="factorTableSearch"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppBar from '@/components/AppBar.vue'
import Loader from '@/components/Loader.vue'
import Plotly from 'plotly.js-dist-min'
import { ApiQueryService } from '@/services/ApiQueryService'
import GeneralUtils from '@/utils/GeneralUtils'
import ColorUtils from '@/utils/ColorUtils'
import * as d3 from 'd3'

@Component({
  components: {
    AppBar,
    Loader
  }
})
export default class Expression extends Vue {
  geneID = ''

  breadcrumbs = [
    {
      text: 'Graph',
      to: '/graph'
    },
    {
      text: 'Expression',
      disabled: true
    }
  ]

  plotData: any[] = []

  violinLayout = {
    title: 'TPM',
    yaxis: {
      zeroline: false
    },
    autosize: true,
    showlegend: false,
    margin: {
      l: 40, // Left margin
      r: 40, // Right margin
      b: 100, // Bottom margin - Increase this value to give more space for x0 labels
      t: 40, // Top margin
      pad: 4
    },
    xaxis: {
      tickangle: -45, // Tilts the labels to make more room. Adjust angle as needed.
      automargin: true // Automatically adjust margins to fit labels
    }
  }

  heatmapLayout = {
    title: 'TPM',
    autosize: true,
    showlegend: false,
    margin: {
      l: 40, // Left margin
      r: 40, // Right margin
      b: 100, // Bottom margin - Increase this value to give more space for x0 labels
      t: 40, // Top margin
      pad: 4
    },
    xaxis: {
      tickangle: -45, // Tilts the labels to make more room. Adjust angle as needed.
      automargin: true // Automatically adjust margins to fit labels
    }
  }

  tissueMarkersRange: number[] = [0, 295]
  tissueMarkersRangeMin = 0
  tissueMarkersRangeMax = 295

  xFactor = 'Tissue'
  yFactor = 'None'
  factorXItems: string[] = ['Treatment', 'Tissue', 'Cultivar']
  factorYItems: string[] = ['Treatment', 'Tissue', 'Cultivar', 'None']

  tissueData = {
    counts: {
      leaves: [23, 5, 19, 0],
      embryo: [0, 11, 28]
    },
    stats: {
      min: 0,
      max: 100
    }
  }

  tissueTableSearch = ''

  tissueTableHeaders = [
    { text: 'Tissue', value: 'tissue' },
    { text: 'Mean TPM', value: 'mean' }
  ]

  tissueTableItems: any[] = []

  factorTableSearch = ''

  factorTableHeaders = [
    { text: 'Factor', value: 'factor' },
    { text: 'Mean TPM', value: 'mean' }
  ]

  factorTableItems: any[] = []

  heatmapMaxTPM = 200

  created () {
    // Set gene ID
    this.geneID = this.$route.params.geneID

    // Resize plot on window resize
    // this.$root.$on('resize', () => {
    //   this.plot()
    // })
  }

  mounted () {
    this.$store.commit('graphStore/setLoading', true)
    ApiQueryService.getGeneExpression(this.geneID, 'part').then((data) => {
      this.$store.commit('graphStore/setLoading', false)

      // Reset plot data
      this.plotData = []

      // Set color scale
      const colorScale = ColorUtils.getColorScale(Object.keys(data.counts).length, true)

      // Set highest mean
      let highestMean = 0
      for (const tissue in data.counts) {
        if (Object.prototype.hasOwnProperty.call(data.counts, tissue)) {
          const counts = data.counts[tissue]
          const mean = GeneralUtils.mean(counts)
          if (mean > highestMean) {
            highestMean = mean
          }
        }
      }

      // ----------------------------
      // Heatmap
      // ----------------------------

      const heatmap = d3.select('#heatmap')

      // Initialize a variable to store the maximum TPM
      let maxTPM = 0

      // Find the maximum TPM
      for (const key in data.freqs.freqs) {
        const freq = data.freqs.freqs[key] as any // Type freq as any
        if (freq.tpm > maxTPM) {
          maxTPM = freq.tpm
        }
      }

      this.heatmapMaxTPM = maxTPM

      const heatmapEl = this.$el.querySelector('#heatmap')
      const heatmapElHeight = heatmapEl ? window.getComputedStyle(heatmapEl).height : '0px'
      const heatmapHeight = parseInt(heatmapElHeight.substring(0, heatmapElHeight.length - 2), 10)

      // Distribute a percentage for any TPM in the list based on the maximum TPM
      for (const key in data.freqs.freqs) {
        const freq = data.freqs.freqs[key] as any // Type freq as any
        const percentageOfMax = (freq.tpm / maxTPM) * 100
        const y = (heatmapHeight * (1 - (percentageOfMax / 100)))

        // Append heatbar
        heatmap.append('rect')
          .attr('class', 'heatbar')
          .attr('x', 0)
          .attr('y', y)
          .attr('width', 30)
          .attr('height', 3)
          .attr('fill', '#B59CEF')

        // // Append mean
        // heatmap.append('rect')
        //   .attr('class', 'mean')
        //   .attr('x', 0)
        //   .attr('y', 250)
        //   .attr('width', 30)
        //   .attr('height', 4)
        //   .attr('fill', 'blue')
      }

      // ----------------------------
      // Plot, Tissue markers, and Table
      // ----------------------------

      // Sort tissues by highest mean TPM
      const tissues = this.sortByHighestMeanTPM(data)

      // Set plot data, tissue markers, and table data
      tissues.forEach(({ f, counts }) => {
        const tissue = f
        // Set plot
        this.plotData.push({
          type: 'violin',
          x0: tissue,
          y: counts,
          name: tissue,
          points: 'none',
          box: {
            visible: true
          },
          boxpoints: false,
          line: {
            color: colorScale.pop() as string
          },
          meanline: {
            visible: true
          }
        })

        // Set tissue marker
        const mean = GeneralUtils.mean(counts)
        const marker = this.$refs[tissue] as any
        if (marker) {
          const opacity = mean / highestMean // marker opacity based on mean / highest mean
          marker.style.backgroundColor = `rgba(255, 0, 0, ${opacity})`
        }

        // Set table data
        this.tissueTableItems.push({
          tissue,
          mean
        })
        this.factorTableItems.push({
          factor: tissue,
          mean
        })
      })

      // Set factor table headers
      this.factorTableHeaders[0].text = 'Tissue'

      // Set tissue markers range
      this.tissueMarkersRangeMin = data.stats.min
      this.tissueMarkersRangeMax = data.stats.max
      this.tissueMarkersRange = [data.stats.min, data.stats.max]

      // Force update to update the slider
      this.$forceUpdate()

      // Plot
      Plotly.newPlot(this.$refs.plotContainer, this.plotData, this.violinLayout, { responsive: true })
    })
  }

  sortByHighestMeanTPM (data: any) {
    const fs = []

    for (const f in data.counts) {
      if (Object.prototype.hasOwnProperty.call(data.counts, f)) {
        let counts = data.counts[f]
        let label = ''
        // Hack for 'Treatment' (treatment counts are objects containing 'tpms')
        if (counts.tpms) {
          label = counts.label
          counts = counts.tpms
        }
        const meanTPM = counts.reduce((acc: any, val: any) => acc + val, 0) / counts.length // Calculate mean TPM
        fs.push({ f, meanTPM, counts, label }) // Add f (any factor), its mean TPM, and counts to the array
      }
    }

    // Sort the array based on the highest mean TPM values in descending order
    fs.sort((a, b) => b.meanTPM - a.meanTPM)

    return fs
  }

  onFactorChange () {
    let xFactor = this.xFactor.toLowerCase()
    let yFactor = this.yFactor ? this.yFactor.toLowerCase() : null

    // Hack for 'Tissue' (called 'part' in the API)
    if (xFactor === 'tissue') xFactor = 'part'
    if (yFactor === 'tissue') yFactor = 'part'

    this.$store.commit('graphStore/setLoading', true)

    if (yFactor && yFactor !== 'none' && xFactor !== yFactor) {
      // Two different factors: generate heatmap
      Promise.all([
        ApiQueryService.getGeneExpression(this.geneID, xFactor),
        ApiQueryService.getGeneExpression(this.geneID, yFactor)
      ]).then(([xData, yData]) => {
        this.generateHeatmap(xData, yData)
      })
    } else {
      // One factor or same factor: generate violin plot
      ApiQueryService.getGeneExpression(this.geneID, xFactor).then((data) => {
        this.generateViolinPlot(data)
      })
    }
  }

  generateHeatmap (xData: any, yData: any) {
    this.$store.commit('graphStore/setLoading', false)

    // Reset data
    this.plotData = []
    this.factorTableItems = []

    // Sort factors by highest mean TPM
    const xFactors = this.sortByHighestMeanTPM(xData)
    const yFactors = this.sortByHighestMeanTPM(yData)

    // Create heatmap data
    const zValues: number[][] = xFactors.map(xFactor =>
      yFactors.map(yFactor => xFactor.meanTPM + yFactor.meanTPM)
    )

    // Set plot data
    this.plotData = [{
      type: 'heatmap',
      z: zValues,
      x: yFactors.map(f => f.f),
      y: xFactors.map(f => f.f),
      hoverongaps: false
    }]

    // Set table data for xFactors only
    xFactors.forEach(({ f, meanTPM }) => {
      this.factorTableItems.push({
        factor: f,
        mean: meanTPM
      })
    })

    // Set factor table headers
    this.factorTableHeaders = [
      { text: this.xFactor, value: 'factor' },
      { text: 'Mean', value: 'mean' }
    ]

    // Plot
    Plotly.newPlot(this.$refs.plotContainer, this.plotData, this.heatmapLayout, { responsive: true })
  }

  generateViolinPlot (data: any) {
    this.$store.commit('graphStore/setLoading', false)

    // Reset data
    this.plotData = []
    this.factorTableItems = []

    // Set color scale
    const colorScale = ColorUtils.getColorScale(Object.keys(data.counts).length, true)

    // Sort factors by highest mean TPM
    const factors = this.sortByHighestMeanTPM(data)

    // Set plot data using sorted factors
    factors.forEach(({ f, counts, label }) => {
      // Set plot
      this.plotData.push({
        type: 'violin',
        x0: f,
        y: counts,
        name: f,
        points: 'none',
        box: {
          visible: true
        },
        boxpoints: false,
        line: {
          color: colorScale.pop() as string
        },
        meanline: {
          visible: true
        },
        text: label
      })

      // Set table data
      this.factorTableItems.push({
        factor: f + (label ? ` (${label})` : ''),
        mean: GeneralUtils.mean(counts)
      })
    })

    // Set factor table headers
    this.factorTableHeaders = [
      { text: this.xFactor, value: 'factor' },
      { text: 'Mean', value: 'mean' }
    ]

    // Plot
    Plotly.newPlot(this.$refs.plotContainer, this.plotData, this.violinLayout, { responsive: true })
  }
}
</script>

<style scoped lang="scss">
#expression {
  margin-top: 64px;
}

.main-title {
  font-size: 32px;
  font-weight: 100;
}

.title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
}

.slider-title {
  font-size: 16px;
  margin-bottom: 16px;
}

.plot-container {
  height: 800px;
}

.tissue-container {
  position: relative;
  width: 100%;

  .tissue-marker-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .tissue-marker {
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: rgba(255, 0, 0, 0);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  img {
    width: 100%;
  }
}

#heatmap {
  width: 30px;
  height: 640px;
  margin: 123px auto 0;
  border: 1px solid #848484;
}

.heatmap-container {
  position: relative;

  .label-container {
    position: absolute;
    top: 0;
    left: 8px;
    width: 0;
  }

  .label{
    position: absolute;
    right: 0;
    text-align: right;

    &.high {
      top: 123px;
    }

    &.low {
      top: 763px;
    }

    &.mean {
      top: 500px;
    }
  }
}

#leaves {
  top: 4%;
  left: 20%;
}
#petiole {
  top: 32%;
  left: 12%;
}
#flower {
  top: 4%;
  left: 45%;
}
#pod {
  top: 4%;
  left: 66%;
}
#suspensor {
  top: 4%;
  left: 85%;
}
#shoot {
  top: 57%;
  left: 10%;
}
#nodules {
  top: 96%;
  left: 12%;
}
#branch-roots {
  top: 92%;
  left: 38%;
}
#embryo {
  top: 41%;
  left: 54%;
}
#endosperm {
  top: 45%;
  left: 50%;
}
#seed-coat {
  top: 54%;
  left: 52%;
}
#seed {
  top: 34%;
  left: 86%;
}
#epicotyl {
  top: 65%;
  left: 57%;
}
#cotyledon {
  top: 71%;
  left: 46%;
}
#hypocotyl {
  top: 78%;
  left: 50%;
}
#radicle {
  top: 88%;
  left: 57%;
}
#seedling {
  top: 58%;
  left: 85%;
}
</style>
