<template>
  <div>
    <!-- Context Menu -->
    <div
      ref="contextMenu"
      class="context-menu"
      v-if="selectionContextMenu.enabled"
      :style="{ top: `${selectionContextMenu.positionY}px`, left: `${selectionContextMenu.positionX}px` }"
    >
      <v-list dense flat>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="init('export')">Export data</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="init('cluster')">Cluster region</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <!-- Run Dialog -->
    <v-dialog
      v-model="runDialog"
      max-width="600px"
      max-height="600px"
      scrollable
      persistent>
      <v-card>
        <v-card-title class="headline">Run the pipeline?</v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="regionName"
                label="Pipeline Name"
                ref="regionNameInput"
                required
                filled
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-card-text><strong>Sequence lengths (bp):</strong></v-card-text>
              <v-spacer></v-spacer>
              <v-simple-table
                fixed-header
                dense
                :style="{ maxHeight: '500px', overflowY: 'scroll' }"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Track</th>
                      <th class="text-right">Seq. length (bp)</th>
                      <th class="text-left">Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="track of Object.keys(lengthMap)" :key="track">
                      <td>{{ track }}</td>
                      <td>{{ lengthMap[track].len === -1 ? '-' : numberWithCommas(lengthMap[track].len) }}</td>
                      <td>{{ lengthMap[track].reasons.slice(0, -2) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-if="vcfFileStr !== ''">
                <v-card-text>VCF extraction in these regions:</v-card-text>
                <v-textarea
                  v-model="refFileStrDisplay"
                  readonly
                  :rows=2>
                </v-textarea>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="runDialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="confirmPipelineRun">Run</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Graph from '@/graph/Graph'
import DataProvider from '@/services/DataProvider'
import Config from '@/graph/Config'
import { Component, Vue } from 'vue-property-decorator'
import PipelinePoller from '@/services/PipelinePoller'
import { ApiQueryService } from '@/services/ApiQueryService'
import GeneralUtils from '@/utils/GeneralUtils'

@Component
export default class selectionContextMenu extends Vue {
  regionName = ''
  runDialog = false
  seqLensList = ''
  bedfileStr = ''
  lengthMap: Record<string, { len: number, reasons: string }> = {}
  vcfFileStr = ''
  refFileStr = ''
  refFileStrDisplay = ''
  pipeTypeToRun = ''

  get selectionContextMenu () {
    return this.$store.state.graphStore.selectionContextMenu
  }

  get leftBin () {
    return this.$store.state.pantoStore.selectedBins.left
  }

  get rightBin () {
    return this.$store.state.pantoStore.selectedBins.right
  }

  mounted () {
    document.addEventListener('mousedown', this.disable)
  }

  beforeDestroy () {
    document.removeEventListener('mousedown', this.disable)
  }

  disable (e: MouseEvent) {
    const contextMenu = this.$refs.contextMenu as HTMLElement
    if (contextMenu && !contextMenu.contains(e.target as Node)) {
      this.$store.dispatch('graphStore/setSelectionContextMenu', { enabled: false })
    }
  }

  numberWithCommas (x: number) {
    return GeneralUtils.numberWithCommas(x)
  }

  init (pipeType: string) {
    this.$store.commit('graphStore/setLoading', true)
    this.pipeTypeToRun = pipeType
    Graph.createRegionBedfile(this.leftBin, this.rightBin)
      .then(({ bedfileStr, lengthMap }) => {
        if (bedfileStr !== '') {
          this.bedfileStr = bedfileStr
          this.lengthMap = lengthMap

          this.seqLensList = bedfileStr.split('\n').map((line) => {
            const [chr, start, end] = line.split('\t')
            return chr + ': ' + GeneralUtils.numberWithCommas((Number(end) - Number(start) + 1))
          }).join('\n')

          this.regionName = bedfileStr.split('\n', 1)[0].replace('\t', '.').replace(/\t(?=[^\t]*$)/, '-')
        }
        Graph.createVCFList(this.leftBin, this.rightBin)
          .then(({ vcfFileStr, refFileStr }) => {
            if (vcfFileStr !== '' && refFileStr === '') {
              alert('Reference paths for the vcf tracks are missing for that region.')
              this.$store.commit('graphStore/setLoading', false)
              return
            } else if (vcfFileStr) {
              this.vcfFileStr = vcfFileStr
              this.refFileStr = refFileStr
              this.refFileStrDisplay = refFileStr.split('\n').map((line) => {
                const [chr, start, end] = line.split(',')
                if (/^\d+$/.test(start) && /^\d+$/.test(end)) { // Check if start and end only contain digits
                  return chr + ': ' + GeneralUtils.numberWithCommas(Number(start)) + '-' + GeneralUtils.numberWithCommas(Number(end)) + ', length: ' + GeneralUtils.numberWithCommas((Number(end) - Number(start) + 1))
                } else {
                  return ''
                }
              }).filter((line) => line !== '').join('\n')
            }

            if (vcfFileStr !== '' || this.bedfileStr !== '') {
              this.showDialog()
            } else {
              this.$store.commit('graphStore/setLoading', false)
              alert('No data to export')
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  showDialog () {
    this.$store.commit('graphStore/setLoading', false)
    this.runDialog = true
  }

  confirmPipelineRun () {
    if (this.regionName === '') {
      alert('Please enter a pipeline name')
      return
    }
    this.runDialog = false
    this.launchPipeline()
  }

  launchPipeline () {
    const cluster = this.pipeTypeToRun === 'cluster'
    const outputPath = cluster
      ? Config.pipelineFolderName + 'clustering/' + this.regionName + '/input'
      : Config.pipelineFolderName + 'seqexport/' + this.regionName + '/input'

    // Store files on s3
    const promises: Promise<void>[] = []
    if (this.bedfileStr !== '') {
      promises.push(DataProvider.storeFile(
        'graphTracks.bed',
        this.bedfileStr,
        outputPath
      ))
    }
    if (this.vcfFileStr !== '') {
      promises.push(DataProvider.storeFile(
        'vcfTracks.csv',
        this.vcfFileStr,
        outputPath
      ))
      promises.push(DataProvider.storeFile(
        'ref.csv',
        this.refFileStr,
        outputPath
      ))

      promises.push(this.writeParamsFile(this.regionName, outputPath, 'vcf' + (cluster ? ',cluster' : '')))
    } else {
      promises.push(this.writeParamsFile(this.regionName, outputPath, (cluster ? 'cluster' : '')))
    }

    Promise.all(promises).then(() => {
      this.$store.commit('graphStore/setLoading', false)

      console.log('All files stored')
      // TODO Start a pipeline
      const pipe = cluster ? 'run_clustering' : 'run_seq_export'
      ApiQueryService.startPipeline(pipe, outputPath + '/' + Config.paramsFileName).then((response: any) => {
        console.log('Pipeline started\n', response)
        this.$store.commit('graphStore/setLoading', false)
        this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Pipeline ' + this.regionName + ' started. See the <a href="/pipelines">status page</a>.', type: 'info' })
        PipelinePoller.poll(20000)
      }).catch((error) => {
        this.$store.commit('graphStore/setLoading', false)
        this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Pipeline ' + this.regionName + ' started. See the <a href="/pipelines">status page</a>.', type: 'info', dismissible: true })
        PipelinePoller.poll(20000)
        console.log(error)
      })
    })
  }

  writeParamsFile (regionName: string, outputPath: string, params: string) {
    return new Promise<void>((resolve, reject) => {
      const projectdir = '"s3://' + Config.s3BucketName + '/' + process.env.VUE_APP_PROJECT_PATH
      let outStr = 'projectdir: ' + projectdir + '"\n'
      outStr += 'panregion: "' + regionName + '"\n'
      outStr += 'bed: ' + projectdir + outputPath + '/graphTracks.bed"\n'
      outStr += 'fasta: ' + projectdir + 'data/genome_seqs/' + this.$store.state.chunkStore.dataset + '.fa"\n'
      outStr += 'data_folder: "' + outputPath.replace('/input', '') + '"\n'
      outStr += 'bin_width: ' + this.$store.state.chunkStore.binWidth + '\n'
      outStr += 'start_bin: ' + Graph.getBinAtViewportLeft() + '\n'
      outStr += 'dataset: "' + this.$store.state.chunkStore.dataset + '"\n'

      if (params.includes('vcf')) {
        outStr += 'vcf: ' + projectdir + outputPath + '/vcfTracks.csv"\n'
        outStr += 'reftsv: ' + projectdir + outputPath + '/ref.csv"\n'
      }
      if (params.includes('cluster')) {
        outStr += 'tree: true\n'
      }

      console.log('Params:\n', outStr)

      DataProvider.storeFile(
        Config.paramsFileName,
        outStr,
        outputPath
      ).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
</script>
