import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { AuthStore } from '@/store/modules/AuthStore'
import { ChunkStore } from '@/store/modules/ChunkStore'
import { MetaStore } from '@/store/modules/MetaStore'
import { GraphStore } from '@/store/modules/GraphStore'
import { PantoStore } from '@/store/modules/PantoStore'
import { GenomeStore } from '@/store/modules/GenomeStore'
import { RootState } from '@/types/StoreTypes'
import { PipelineStore } from './modules/PipelineStore'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    authStore: AuthStore,
    chunkStore: ChunkStore,
    metaStore: MetaStore,
    graphStore: GraphStore,
    pantoStore: PantoStore,
    genomeStore: GenomeStore,
    pipelineStore: PipelineStore
  },
  // persist state of attachedTo, to be able to refresh the page
  plugins: [createPersistedState({
    paths: ['authStore.token']
  })]

}

export default new Vuex.Store<any>(store)
