// Vue
import Vue from 'vue'
import store from '@/store'

// Pixi
import * as PIXI from 'pixi.js'
import { Viewport } from 'pixi-viewport'

// Config
import Config from './Config'

// Types
import { BinInfo, ChunkData, EditObject, Selection, vcfBinData, QTLg } from '@/types/Types'

// Utils
import GeneralUtils from '@/utils/GeneralUtils'
import SortingUtils from '../utils/SortingUtils'

// Services
import DataProvider from '@/services/DataProvider'

// Graph modules
import SortingTools from './modules/SortingTools'
import Tooltip from './modules/Tooltip'

// Xtras
import chroma, { Color } from 'chroma-js'
import QTLService from '@/services/QTLService'
import { ApiQueryService } from '@/services/ApiQueryService'

// -------------------------------------------------

// Colors
const fillColor: number = Config.fillColor
const invColor: number = Config.invColor
const duplColor: number = Config.duplColor
const invDuplColor: number = Config.invDuplColor
const emptyColor: number = Config.emptyColor
const geneColor: number = Config.intronColor
const exonColor: number = Config.exonColor
const spacerColor: number = Config.spacerColor
const backgroundColor: number = Config.backgroundColor
const nextArrowColor: number = Config.nextArrowColor
const invColorPaletteScale: chroma.Scale = chroma.scale([GeneralUtils.numToHex(fillColor), GeneralUtils.numToHex(invColor)])
const fillColorPaletteScale: chroma.Scale = chroma.scale([chroma(emptyColor).darken(0.5), GeneralUtils.numToHex(fillColor)])
const duplColorPaletteArray: Array<string> = chroma.scale([GeneralUtils.numToHex(duplColor), GeneralUtils.numToHex(spacerColor)]).colors(8)
const linkColorPalette: chroma.Scale = chroma.scale('Spectral')

// Sizes
let cellHeight: number
let cellWidth: number
let cellMargin: number
let cellWidthMargin: number
let topMargin: number
let linkHeights: Array<boolean>

// -------------------------------------------------

// App
let app: PIXI.Application

// Graph
let graph: HTMLCanvasElement

// Viewport
let matrixViewport: Viewport

// Containers
const sequenceContainer: PIXI.Container = new PIXI.Container()
const metaContainer: PIXI.Container = new PIXI.Container()
const qtlContainer: PIXI.Container = new PIXI.Container()

// Graphics
const spacer: PIXI.Graphics = new PIXI.Graphics()
const highlightColumnRect: PIXI.Graphics = new PIXI.Graphics()
const qtlBackground: PIXI.Graphics = new PIXI.Graphics()
const metaBackground: PIXI.Graphics = new PIXI.Graphics()

// -------------------------------------------------
// GROUPS (GPU memory management)
// -------------------------------------------------

// Tracks
let trackContainerGroup: Array<PIXI.Container> = []
let graphGraphicsGroup: Array<PIXI.Graphics> = []
let readGraphicsGroup: Array<PIXI.Graphics> = []
let vcfGraphicsGroup: Array<PIXI.Graphics> = []
let qtlGraphicsGroup: Array<PIXI.Graphics> = []

// Sequence
let sequenceTextGroup: Array<PIXI.Text> = []
let sequenceBackgroundGroup: Array<PIXI.Graphics> = []

// Metadata
let metaGraphicsGroup: Array<PIXI.Graphics> = []
let metaTextGroup: Array<PIXI.Text> = []

// Links
let linkTextGroup: Array<PIXI.Text> = []

// -------------------------------------------------

// Modules
let sortingTools: SortingTools

// Tooltip
let stickTooltip: boolean

// Drawn chunks
const drawnChunks: Set<number> = new Set<number>()

// Positions
let mVleft: number

// -------------------------------------------------

const Graph = {
  // -------------------------
  // Init functions
  // -------------------------
  init () {
    // Init graph container
    graph = document.getElementById('graph') as HTMLCanvasElement

    // Init pixi app
    app = new PIXI.Application<HTMLCanvasElement>({
      antialias: true,
      backgroundColor,
      width: graph.offsetWidth,
      height: graph.offsetHeight,
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      resizeTo: graph
    })
    graph.appendChild(app.view as HTMLCanvasElement)

    // to be able to use zIndex
    app.stage.sortableChildren = true

    // Init Tooltip
    Tooltip.init()

    // Init containers
    this.initStaticGraphics()

    // Init matrix viewport
    this.initMatrixViewport()

    // Init stage
    this.setStage()
  },

  initStaticGraphics () {
    // zIs
    sequenceContainer.zIndex = 1
    metaContainer.zIndex = 2
    qtlContainer.zIndex = 4
    qtlBackground.zIndex = 3

    // Add to stage
    app.stage.addChild(sequenceContainer)
    app.stage.addChild(metaContainer)
    app.stage.addChild(qtlContainer)
    app.stage.addChild(qtlBackground)
  },

  initMatrixViewport () {
    let screenWidth
    let screenHeight
    if (!graph) {
      screenWidth = window.innerWidth
      screenHeight = window.innerHeight
    } else {
      screenWidth = graph.getBoundingClientRect().width
      screenHeight = graph.getBoundingClientRect().height
    }

    // Init matrix viewport
    matrixViewport = new Viewport({
      screenWidth,
      screenHeight,
      worldWidth: 0,
      worldHeight: 0,
      disableOnContextMenu: true,
      events: app.renderer.events
    })

    // Activate plugins
    matrixViewport
      .drag()
      .pinch()
      .wheel()
      .clampZoom({ minScale: 0.5, maxScale: 4 })
      .decelerate({ minSpeed: 0.1, friction: 0.95 })

    // Load neighboring chunk(s) when user hits bounce box
    matrixViewport.on('bounce-x-start', async () => {
      // Prevent another bounce if we are already loading or drawing
      if (store.state.graphStore.loading) {
        return
      }
      store.commit('graphStore/setLoading', true)
      this.deactivateBounce() // security measurement to not invoke another bounce

      // setTimeout(async () => {
      await this.drawChunksFillScreen()
      // }, 50) // to accommodate for rapid changes of matrixViewport.left (e.g. when zooming out fast)

      store.commit('graphStore/setLoading', false)
    })

    matrixViewport.on('moved', (e) => {
      if (e.type !== 'bounce-x') {
        // TODO: should be called only once (if visible)
        Tooltip.hide()
        this.alignContainers()
        this.setSliderPosition()
      }
    })

    matrixViewport.on('zoomed', () => {
      this.setScale(matrixViewport.scale.x)

      // Set chunkStore scale (ObservablePoint)
      store.commit('chunkStore/setCurrentZoomLevel', matrixViewport.scale)
      // Set graphStore scale (Number)
      store.commit('graphStore/setScale', matrixViewport.scale.x)
    })

    matrixViewport.on('clicked', (e) => {
      // -----------
      // Right click
      // -----------
      if (GeneralUtils.isRightClick(e)) {
        // console.log('right click: scr/scr scaled/wld/wld col/wld bin', e.screen, Number(e.screen.x) * matrixViewport.scale.x, e.world.x, this.getColForX(e.world.x), this.getBinInfoForRawX(e.world.x).binNumber, 'metaWid/scaled', store.state.metaStore.metaContainerWidth, store.state.metaStore.metaContainerWidth * matrixViewport.scale.x, 'scale', matrixViewport.scale.x)

        if (e.screen.x > store.state.metaStore.metaContainerWidth * matrixViewport.scale.x) {
          // Highlight column
          this.highlightColumn(this.getColForX(e.world.x))

          // ContextMenu
          const contextMenuX = e.screen.x + 30
          const contextMenuY = e.screen.y + 145
          store.dispatch('graphStore/setContextMenu', {
            enabled: true,
            positionX: contextMenuX,
            positionY: contextMenuY,
            rawCoords: e.world,
            isColumnLink: this.isColumnLink(e.world.x),
            vcfTracksVisible: store.getters['chunkStore/getVisibleTracks'].vcfTracks.length > 0
          })
        }
      } else {
        // ----------
        // Left click
        // ----------

        // Remove highlight on any left click
        Graph.removeHighlight()

        // If tooltip is already sticked we remove it on left click
        if (stickTooltip) {
          stickTooltip = false
          this.hideTooltip()
        }

        // Stick tooltip on left click of tooltip is already displayed on mouse over
        if (store.state.metaStore.tooltipShown) {
          stickTooltip = true
        }

        // ------- QTL track highlighting
        if (e.world.y < matrixViewport.top + qtlContainer.height) {
          // store.state.chunkStore.qtlsInCachedChunks.forEach((value: any) => {
          store.state.pantoStore.enabledQTLTracks.forEach((qtl: QTLg) => {
            // const qtl = value as QTLg
            if (e.screen.y > qtl.y && e.screen.y < qtl.y + store.state.chunkStore.qtlCellHeight) {
              // Set the selected QTL + highlight it
              store.commit('graphStore/selectedQTL', qtl as QTLg)
              store.commit('graphStore/qtlHighlight', { enabled: true, y: qtl.y, height: store.state.chunkStore.qtlCellHeight })
              // Open the QTL menu
              store.commit('pantoStore/setSideBar', { enabled: true, target: 'QTLMenu' })
            }
          })
        } else {
          // disable qtl highlighting
          store.commit('graphStore/qtlHighlight', { enabled: false })

          // ------- Open gene passport
          if (this.cellHasGene(e.world.x, e.world.y)) {
            // store.commit('graphStore/setLeftmostBin', this.getBinAtViewportLeft())
            this.showGeneMenu(e.world)
            this.hideTooltip()
          } else {
            store.commit('pantoStore/setSideBar', { enabled: false, target: null })
          }
        }
      }
    })

    matrixViewport.on('drag-start', () => {
      if (stickTooltip) stickTooltip = false
    })

    app.stage.addChild(matrixViewport)
  },

  async drawChunksFillScreen (): Promise<number[]> {
    return new Promise<number[]>((resolve) => {
      const cachedGraphTracks = store.getters['chunkStore/getVisibleTracks'].graphTracks.join()
      this.loadChunksToFillScreen()
        .then((newChunks) => {
          if (newChunks) {
            this.filterTracksByCoverageAndSort().then(() => {
              if (cachedGraphTracks !== store.getters['chunkStore/getVisibleTracks'].graphTracks.join()) {
                const col = this.getColForBin(matrixViewport.left + store.state.metaStore.metaContainerWidth)
                // We draw all cached chunks, since the num tracks changed
                this.drawCachedChunks(col, store.state.graphStore.selectedHighlight)
              } else {
                // We only draw the new chunks, since num tracks doesn't change
                this.drawChunks(newChunks)
              }
            }).finally(() => {
              resolve(newChunks)
            })
          }
        })
        .catch((error) => {
          console.error('Cannot load additional chunks', error)
        })
    })
  },

  checkSide (left: number, right: number) {
    let xStart = (store.state.chunkStore.currentFirstColumn - 1) * cellWidthMargin
    let xEnd = store.state.chunkStore.currentLastColumn * cellWidthMargin + store.state.metaStore.metaContainerWidth

    if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
      xStart = (store.state.chunkStore.currentFirstBin - 1) * cellWidthMargin
      xEnd = store.state.chunkStore.currentLastBin * cellWidthMargin
    }

    if (left < xStart) {
      return 'left'
    } else if (right > xEnd) {
      return 'right'
    }
    return 'inside'
  },

  showGeneMenu (coords: PIXI.Point) {
    store.dispatch('pantoStore/setSideBarAsync', { enabled: true, target: 'GeneMenu', wide: true }).then(() => {
      const geneInfos = Graph.getGeneInfos(coords.x, coords.y)
      store.commit('pantoStore/setSelectedGene', { id: geneInfos.name, assembly: geneInfos.track })
    }).catch((error) => {
      console.error(error)
    })
  },

  setStage () {
    // Init sizes
    cellWidth = Config.cellWidth
    cellMargin = Config.cellMargin
    cellWidthMargin = cellWidth + cellMargin
    cellHeight = Config.cellHeight + cellMargin
    topMargin = Config.topMargin
    linkHeights = Array(Config.maxNrLinks).fill(false)

    // Align main containers
    this.alignContainers()

    // Scale retreived from query param (sharing), see applyQuery in PView
    // TODO: we should only use this property to keep track of the scale: remove chunkStore.currentZoomLevel
    if (store.state.graphStore.scale) {
      this.setScale(store.state.graphStore.scale)
    } else {
      this.setScale(store.state.chunkStore.currentZoomLevel.x)
    }

    // Init tooltip
    // TODO: init tooltip once in app init
    this.initTooltip()
  },

  resetStage () {
    drawnChunks.clear() // reset drawn chunks
    this.resetContainers() // remove all children from containers
    this.clearGPUMemory() // destroy all PIXI objects
    store.commit('graphStore/qtlHighlight', { enabled: false, y: 0, height: 0 }) // reset QTL highlight
    this.setStage() // re-init stage
  },

  resetContainers () {
    matrixViewport.removeChildren()
    sequenceContainer.removeChildren()
    metaContainer.removeChildren()
    qtlContainer.removeChildren()
  },

  clearGPUMemory () {
    // Destroy track containers
    for (let i = 0; i < trackContainerGroup.length; i++) {
      trackContainerGroup[i].destroy()
    }
    trackContainerGroup = []

    // Destroy graph tracks
    for (let i = 0; i < graphGraphicsGroup.length; i++) {
      graphGraphicsGroup[i].destroy()
    }
    graphGraphicsGroup = []

    // Destroy read tracks
    for (let i = 0; i < readGraphicsGroup.length; i++) {
      readGraphicsGroup[i].destroy()
    }
    readGraphicsGroup = []

    // Destroy vcf tracks
    for (let i = 0; i < vcfGraphicsGroup.length; i++) {
      vcfGraphicsGroup[i].destroy()
    }
    vcfGraphicsGroup = []

    // Destroy QTL tracks
    for (let i = 0; i < qtlGraphicsGroup.length; i++) {
      qtlGraphicsGroup[i].destroy()
    }
    qtlGraphicsGroup = []

    // Destroy sequence backgrounds
    for (let i = 0; i < sequenceBackgroundGroup.length; i++) {
      sequenceBackgroundGroup[i].destroy()
    }
    sequenceBackgroundGroup = []

    // Destroy sequence texts
    for (let i = 0; i < sequenceTextGroup.length; i++) {
      sequenceTextGroup[i].destroy()
    }
    sequenceTextGroup = []

    // Destroy meta graphics
    for (let i = 0; i < metaGraphicsGroup.length; i++) {
      metaGraphicsGroup[i].destroy()
    }
    metaGraphicsGroup = []

    // Destroy meta texts
    for (let i = 0; i < metaTextGroup.length; i++) {
      metaTextGroup[i].destroy()
    }
    metaTextGroup = []

    // Destroy link texts
    for (let i = 0; i < linkTextGroup.length; i++) {
      linkTextGroup[i].destroy()
    }
    linkTextGroup = []
  },

  // Check if mouse is not moving then show tooltip after x (time) sec
  initTooltip () {
    const time = 500
    let timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      // Init timeout
    })
    matrixViewport.on('pointermove', (e) => {
      if (!stickTooltip) {
        this.hideTooltip()
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          const coords = {
            screen: e.data.global,
            world: matrixViewport.toWorld(e.data.global)
          }
          this.showTooltip(coords)
        }, time)
      }
    })
  },

  // Show tooltip
  showTooltip (coords: { screen: PIXI.Point, world: PIXI.Point }) {
    // Don't show tooltip if ContextMenu is opened or if any overlay is hovered or if mouse is over QTLContainer
    if (!store.state.graphStore.contextMenu.enabled && !store.state.pantoStore.overlayHovered && coords.screen.y > qtlContainer.height && coords.world.x > 0) {
      Tooltip.show()
      store.commit('metaStore/setTooltipShown', true)
      // Set content (meta or world)
      const relativeX = coords.world.x - matrixViewport.left
      if (relativeX <= store.state.metaStore.metaContainerWidth) {
        if (sortingTools) {
          Tooltip.toggleMetaTooltip(coords, relativeX, graph, sortingTools.tooltipTarget)
        }
      } else if (relativeX > store.state.metaStore.metaContainerWidth) {
        Tooltip.toggleWorldTooltip(coords, graph)
      }
    }
  },

  // Hide tooltip
  hideTooltip () {
    Tooltip.hide()
    store.commit('metaStore/setTooltipShown', false)
  },

  deactivateBounce () {
    matrixViewport.bounce({
      sides: 'none'
    })
  },

  updateBounceBox () {
    // The viewport must at least have the size of the screen/container,
    // otherwise paths are centered and cannot really be moved around on the canvas

    let xStart = (store.state.chunkStore.currentFirstColumn - 1) * cellWidthMargin
    let xEnd = (store.state.chunkStore.currentLastColumn + 1) * cellWidthMargin + store.state.metaStore.metaContainerWidth
    if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
      xStart = (store.state.chunkStore.currentFirstBin - 1) * cellWidthMargin
      xEnd = (store.state.chunkStore.currentLastBin + 1) * cellWidthMargin + store.state.metaStore.metaContainerWidth
    }
    const yStart = -1 * matrixViewport.screenHeight / 2
    const yEnd = matrixViewport.screenHeight

    matrixViewport.bounce({
      sides: 'horizontal',
      underflow: 'left',
      time: 0,
      friction: 0,
      bounceBox: new PIXI.Rectangle(xStart, yStart, xEnd, yEnd)
    })
  },

  deleteChunk (side: string) {
    const chunks: Array<any> = Object.keys(store.state.chunkStore.cachedChunks)

    // If there are no chunks, we can't delete any
    if (chunks.length === 0) return false

    while (Object.keys(store.state.chunkStore.cachedChunks).length > Config.maxNrCachedChunks) {
      let chunkNrToDel: any

      if (side === 'left') {
        chunkNrToDel = Math.min(...chunks)
      } else if (side === 'right') {
        chunkNrToDel = Math.max(...chunks)
      }

      const chunkToDel = store.state.chunkStore.cachedChunks[chunkNrToDel]
      for (const p of Object.keys(chunkToDel.tracks)) {
        store.commit('chunkStore/deleteGraphTrack', { id: p, val: 'cov_bins' in chunkToDel.tracks[p] ? chunkToDel.tracks[p].cov_bins : chunkToDel.nrBins })
        store.commit('chunkStore/deleteRawGraphTrack', { id: p, val: 'cov_bins' in chunkToDel.tracks[p] ? chunkToDel.tracks[p].cov_bins : chunkToDel.nrBins })
      }
      store.commit('chunkStore/decrCachedBins', chunkToDel.nrBins)
      store.commit('chunkStore/deleteCachedChunks', chunkNrToDel)
      drawnChunks.delete(chunkNrToDel)
      this.updateCachedChunksCoords()
    }

    return true
  },

  updateCachedChunksCoords () {
    const chunks: Array<any> = Object.keys(store.state.chunkStore.cachedChunks) // Array<number> is not accepted
    const leftChunk = Math.min(...chunks)
    const rightChunk = Math.max(...chunks)
    store.commit('chunkStore/setCurrentFirstBin', store.state.chunkStore.cachedChunks[leftChunk].firstBin)
    store.commit('chunkStore/setCurrentFirstColumn', store.state.chunkStore.cachedChunks[leftChunk].firstCol)
    store.commit('chunkStore/setCurrentLastBin', store.state.chunkStore.cachedChunks[rightChunk].lastBin)
    store.commit('chunkStore/setCurrentLastColumn', store.state.chunkStore.cachedChunks[rightChunk].lastCol)
  },

  filterTracksByCoverageAndSort () {
    return new Promise<void>((resolve) => {
      // let maxCov = 0
      let sortNeeded = false
      for (const path of [...store.state.chunkStore.rawGraphTracks.keys()]) {
        if (store.state.chunkStore.rawGraphTracks.get(path) / store.state.chunkStore.cachedBins < store.state.metaStore.covFraction) {
          store.commit('chunkStore/deleteGraphTrack', path)
          // sortNeeded = true
        } else if (!(store.state.chunkStore.graphTracks.has(path))) {
          const cov = store.state.chunkStore.rawGraphTracks.get(path)
          store.commit('chunkStore/addGraphTrack', { id: path, val: cov })
          sortNeeded = true
        }
        // if (store.state.chunkStore.rawGraphTracks.get(path) > maxCov) {
        //   maxCov = store.state.chunkStore.rawGraphTracks.get(path)
        // }
      }
      if (sortNeeded) SortingUtils.sortTracks('graph')
      resolve()
    })
  },

  setScale (scale: number) {
    // Just add any container here to apply scale on both axis
    [
      matrixViewport,
      sequenceContainer,
      metaContainer
    ].forEach(container => {
      container.scale.set(scale)
    })
    // Exceptions (scale applied on one axis only)
    qtlContainer.scale.x = scale
  },

  highlightColumn (column: number) {
    // Remove previous highlight
    this.removeHighlight()

    if (column >= 0) {
      store.commit('graphStore/setSelectedHighlight', column)

      // x, y
      const x = store.state.metaStore.metaContainerWidth + column * cellWidthMargin
      let y = 0

      if (store.state.metaStore.maxLinkHeight > 0) {
        y = -((store.state.metaStore.maxLinkHeight * cellHeight) + topMargin)
      }

      // width, height
      const width = cellWidth
      const height = -y + (store.getters['chunkStore/getAllVisibleTracks'].length + 2) * (cellHeight)
      // if (store.state.metaStore.readsInFiles) {
      //   height += (store.state.metaStore.readsetNames.length + Config.blankRowsBetweenTrackTypes) * cellHeight
      // }

      // Don't highlight if we are on the metaContainer
      if (x < store.state.metaStore.metaContainerWidth) return

      this.addHighlight(x, y, width, height)
    }
  },

  highlightRegion (leftCol: number, topRow: number, rightCol: number, bottomRow: number) {
    // Remove previous highlight
    this.removeHighlight()

    // Left
    const x = store.state.metaStore.metaContainerWidth + leftCol * cellWidthMargin
    // Top
    const y = (topRow * cellHeight) - 1
    // Width
    const width = (rightCol - leftCol) * cellWidth
    // Height
    const height = ((bottomRow - topRow) * cellHeight) - 1

    // Add highlight
    this.addHighlight(x, y, width, height)
  },

  highlightRegionX (leftCol: number, rightCol: number) {
    // Remove previous highlight
    this.removeHighlight()

    // Left
    const x = store.state.metaStore.metaContainerWidth + leftCol * cellWidthMargin
    // Top
    const y = topMargin
    // Width
    const width = (rightCol - leftCol + 1) * cellWidth
    // Height
    const height = (store.getters['chunkStore/getAllVisibleTracks'].length + 2) * cellHeight - topMargin

    // Add highlight
    this.addHighlight(x, y, width, height)
  },

  addHighlight (x: number, y: number, width: number, height: number) {
    // Draw highlight
    highlightColumnRect.clear()
    highlightColumnRect.lineStyle(1, 0xffd700)
    highlightColumnRect.beginFill(0xffd700, 0.5)
    highlightColumnRect.drawRect(x, y, width, height)
    highlightColumnRect.endFill()

    // Add highlight to viewport
    sequenceContainer.addChild(highlightColumnRect)
    // Store highlight state
    store.commit('graphStore/setIsHighlighted', true)
  },

  removeHighlight () {
    // Remove highlight
    sequenceContainer.removeChild(highlightColumnRect)
    // Store highlight state
    store.commit('graphStore/setIsHighlighted', false)
    store.commit('graphStore/setSelectedHighlight', null)
  },

  drawEndSpacers () {
    if (DataProvider.getLastFileIndex() in store.state.chunkStore.cachedChunks) {
      if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
        this.drawSpacer(store.state.metaStore.metaContainerWidth + (store.state.chunkStore.currentLastColumn + 2) * cellWidthMargin)
      } else {
        this.drawSpacer(store.state.metaStore.metaContainerWidth + (store.state.chunkStore.currentLastBin + 2) * cellWidthMargin)
      }
    }
    if (0 in store.state.chunkStore.cachedChunks) {
      this.drawSpacer(store.state.metaStore.metaContainerWidth - 2 * cellWidthMargin)
    }
  },

  drawSpacer (x: number) {
    const y = 0
    const width = cellWidth
    const height = (store.getters['chunkStore/getAllVisibleTracks'].length + 2) * (cellHeight) - 1
    spacer.clear()
    spacer.beginFill(spacerColor)
    spacer.drawRect(x, y, width, height)
    spacer.endFill()
    matrixViewport.addChild(spacer)
  },

  // Used to shift xcoords on draw/redraw to center the view on the highlight (if active)
  getShiftedX (x: number) {
    // x = x - store.state.metaStore.metaContainerWidth - (matrixViewport.right - matrixViewport.left) / 2
    // x -= (matrixViewport.screenWidth - store.state.metaStore.metaContainerWidth) / 2
    x -= ((matrixViewport.screenWidth - (store.state.metaStore.metaContainerWidth * matrixViewport.scale.x)) / 2) / matrixViewport.scale.x
    return Math.max(x, 0)
  },

  async loadAndDrawChunks (startBin = 1, highlight = store.state.graphStore.selectedHighlight !== null) {
    this.resetStage()
    this.cleanupChunks()

    this.deactivateBounce()

    // TODO: sth for init() ?
    if (store.state.metaStore.denseView) {
      cellWidth = Config.denseCellWidth
      cellWidthMargin = Config.denseCellWidth
    }
    if (!store.state.metaStore.drawCellMargin) {
      cellWidthMargin = cellWidth
    }

    // get chunks to the left and right of startBin
    const chunkID = DataProvider.getFileIndexForBinPos(startBin)
    const lastFileId = DataProvider.getLastFileIndex()
    const chunkIDsToDraw = []
    const nrChunksToLoadEachSide = Config.nrChunksToLoadEachSide

    for (let i = Math.max(0, chunkID - nrChunksToLoadEachSide); i <= Math.min(chunkID + nrChunksToLoadEachSide, lastFileId); i++) {
      chunkIDsToDraw.push(i)
    }

    try {
      await DataProvider.loadTracks(chunkIDsToDraw)
    } catch (error) {
      console.error('Cannot load chunks chunkIDsToDraw', chunkIDsToDraw, error)
    }

    let xLeftCol = this.getXForBin(startBin, chunkID)
    matrixViewport.left = xLeftCol
    if (highlight) {
      xLeftCol = this.getShiftedX(xLeftCol)
      matrixViewport.left = xLeftCol
    }

    // check if we have to load more chunks to fill the screen
    try {
      await this.loadChunksToFillScreen()
    } catch (error) {
      console.error('Cannot load additional chunks', error)
    }

    // Adjust max nr loaded chunks, config setting nrChunksToLoadEachSide has higher priority than maxNrCachedChunks
    const cachedChunks = Object.keys(store.state.chunkStore.cachedChunks).map(Number)
    if (cachedChunks.length > Config.maxNrCachedChunks) {
      Config.maxNrCachedChunks = cachedChunks.length
    }

    await this.filterTracksByCoverageAndSort()

    // Draw metadata
    this.drawMetadata()

    // Draw graph, variant, and read tracks
    this.drawChunks(cachedChunks)

    if (highlight) {
      // draw highlight after loading tracks, since the number of tracks could
      // have changed and thus the height of the highlight column
      // draw it after metadata, since the metadata container width not known before
      this.highlightColumn(this.getColForBin(startBin))
    }
  },

  drawCachedChunks (startBin = 0, highlightColumn = false, anchorViewport = 'undefined') {
    return new Promise<void>((resolve) => {
      // console.log('[DRAW cachedChunks]', Object.keys(store.state.chunkStore.cachedChunks))
      this.resetStage()

      let viewAnchor = matrixViewport.left
      if (anchorViewport === 'right') {
        viewAnchor = matrixViewport.right
      }
      const viewCenter = matrixViewport.center
      const viewTop = matrixViewport.top

      // TODO place in setStage() e.g.
      if (store.state.metaStore.denseView) {
        cellWidth = Config.denseCellWidth
        cellWidthMargin = Config.denseCellWidth
      }
      if (!store.state.metaStore.drawCellMargin) {
        cellWidthMargin = cellWidth
      }

      // Draw metadata
      this.drawMetadata()

      // Draw chunks: graph, variant, and read tracks
      const cachedChunks = Object.keys(store.state.chunkStore.cachedChunks).map(Number)
      this.drawChunks(cachedChunks).then(() => {
        if (startBin !== 0) {
          const column = this.getColForBin(startBin)
          let xcoord = column * cellWidthMargin
          if (highlightColumn) {
            // We shift xcoord to center the view on the highlight
            xcoord = this.getShiftedX(xcoord)
            this.highlightColumn(column)
            store.commit('graphStore/setSelectedHighlight', column)
          }
          matrixViewport.left = xcoord
          matrixViewport.top = viewTop
        } else {
          if (anchorViewport === 'left') {
            matrixViewport.left = viewAnchor
            matrixViewport.top = viewTop
          } else if (anchorViewport === 'right') {
            matrixViewport.right = viewAnchor
            matrixViewport.top = viewTop
          } else if (anchorViewport === 'undefined') {
            // matrixViewport.center = viewCenter
            // matrixViewport.top = viewTop
          }
        }

        // Update current nb tracks
        // store.commit('chunkStore/setNbTracks', store.getters['chunkStore/getVisibleTracks'].graphTracks.length)

        resolve()
      })
    })
  },

  getBounceBoxBorderX (direction: string) {
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      if (direction === 'left') {
        return (store.state.chunkStore.currentFirstColumn - 1) * cellWidthMargin
      } else if (direction === 'right') {
        return store.state.chunkStore.currentLastColumn * cellWidthMargin + store.state.metaStore.metaContainerWidth
      }
    } else {
      if (direction === 'left') {
        return (store.state.chunkStore.currentFirstBin - 1) * cellWidthMargin
      } else if (direction === 'right') {
        return store.state.chunkStore.currentLastBin * cellWidthMargin
      }
    }
  },

  getBounceBoxBorderCol (direction: string) {
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      if (direction === 'left') {
        return store.state.chunkStore.currentFirstColumn
      } else if (direction === 'right') {
        return store.state.chunkStore.currentLastColumn
      }
    } else {
      if (direction === 'left') {
        return store.state.chunkStore.currentFirstBin
      } else if (direction === 'right') {
        return store.state.chunkStore.currentLastBin
      }
    }
  },

  loadChunksToFillScreen (): Promise<Array<number>> {
    // eslint-disable-next-line
    return new Promise<Array<number>>(async (resolve, reject) => {
      const additionalChunks: number[] = []
      let xBounceBoxBorderLeft = (this.getBounceBoxBorderCol('left') - 1) * cellWidthMargin
      let xBounceBoxBorderRight = (this.getBounceBoxBorderCol('right') - 1) * cellWidthMargin

      while (matrixViewport.left < xBounceBoxBorderLeft ||
             matrixViewport.right > xBounceBoxBorderRight) {
        // HACK. Sometimes matrixViewport.left is set to 0 for whatever reason. The following
        // should prevent setting this, unless chunk 0 or chunk 1 are really loaded already
        if (matrixViewport.left <= 0 && !(0 in store.state.chunkStore.cachedChunks) && !(1 in store.state.chunkStore.cachedChunks)) {
          matrixViewport.left = mVleft
        } else if (matrixViewport.left <= 0) {
          matrixViewport.left = 0
        }

        const cachedChunks: Array<number> = Object.keys(store.state.chunkStore.cachedChunks) as unknown as number[]
        let chunkToLoad = -1
        if (matrixViewport.left < xBounceBoxBorderLeft) {
          chunkToLoad = Math.min(...cachedChunks) - 1
        } else if (matrixViewport.right > xBounceBoxBorderRight) {
          chunkToLoad = Math.max(...cachedChunks) + 1
        }

        if (chunkToLoad >= 0 && chunkToLoad <= DataProvider.getLastFileIndex()) {
          try {
            // delete chunks from 'other side' if there are or after the loadTracks function below will be more loaded
            // chunks than allowed, and only if there are enough chunks loaded that fill the screen on 'the other side'
            if (cachedChunks.length >= Config.maxNrCachedChunks) {
              if (matrixViewport.left < xBounceBoxBorderLeft && matrixViewport.right <= xBounceBoxBorderRight) {
                this.deleteChunk('right')
              } else if (matrixViewport.right > xBounceBoxBorderRight && matrixViewport.left >= xBounceBoxBorderLeft) {
                this.deleteChunk('left')
              }
            }
            // following function updates xBounceBoxBorderLeft/xBounceBoxBorderRight and store.cachedChunks:
            await DataProvider.loadTracks([chunkToLoad])
          } catch (error) {
            reject(error)
          }
          additionalChunks.push(chunkToLoad)
        } else {
          // break if we hit the start or end of the pangenome
          break
        }
        xBounceBoxBorderLeft = (this.getBounceBoxBorderCol('left') - 1) * cellWidthMargin
        xBounceBoxBorderRight = (this.getBounceBoxBorderCol('right') - 1) * cellWidthMargin
      }
      resolve(additionalChunks)
    })
  },

  alignContainers () {
    if (matrixViewport.left <= 0 && !(0 in store.state.chunkStore.cachedChunks)) {
      matrixViewport.left = mVleft
    } else {
      mVleft = matrixViewport.left
    }

    // Align containers after a short delay to prevent containers misalignment in some cases
    // NOTE: still not pretty clear why this is happening so randomly
    let yOffset = 0
    if (store.state.chunkStore.qtlsInCachedChunks) {
      yOffset = [...store.state.chunkStore.qtlsInCachedChunks.keys()].length * (store.state.chunkStore.qtlCellHeight + 1)

      // TODO sometimes the 'draggable' area is smaller than the num tracks and you can only drag in the upper ~half of the screen. The following brutal hack is to alleviate this issue
      // if (yOffset > matrixViewport.height) matrixViewport.height *= 2
    }

    setTimeout(() => {
      sequenceContainer.position.x = matrixViewport.position.x
      if (matrixViewport.position.y < yOffset) sequenceContainer.position.y = yOffset
      else sequenceContainer.position.y = matrixViewport.position.y
      metaContainer.position.y = matrixViewport.position.y
      qtlContainer.position.x = matrixViewport.position.x
    }, 50)
  },

  setSliderPosition () {
    store.commit('pantoStore/setSliderPosition', [
      this.getBinInfoForRawX(matrixViewport.left + store.state.metaStore.metaContainerWidth).binNumber,
      this.getBinInfoForRawX(matrixViewport.left + matrixViewport.screenWidth).binNumber
    ])
  },

  drawChunks (chunkIDs: number[]) {
    return new Promise<void>((resolve) => {
      // The last x offset includes all arrivals/departures except the last one
      // TODO Check for arrival/departure on last bin
      for (const chunkID of chunkIDs) {
        const chunk = store.state.chunkStore.cachedChunks[chunkID]

        if (!drawnChunks.has(chunkID)) {
          store.commit('chunkStore/setCurrentChunk', chunkID)

          // Draw tracks of chunk
          this.drawChunk(chunk, chunkID)

          // Add chunk ID to drawnChunks
          drawnChunks.add(+chunkID)

          // Possibly draw end spacer
          this.drawEndSpacers()

          // Draw sequence text
          if (!store.state.metaStore.denseView) {
            this.drawSequenceText(chunk)
          }

          // Draw QTL tracks
          if (store.state.pantoStore.enabledQTLTracks.length > 0) {
            this.drawQTLTracks()
          }

          // Draw links
          if (store.state.metaStore.drawLinks) {
            this.drawConnectedLinks(matrixViewport)
          }

          // Update current nb tracks for bottom bar
          store.commit('chunkStore/setNbTracks', store.getters['chunkStore/getVisibleTracks'].graphTracks.length)
        } else {
          console.log('[DRAW] ALREADY DRAWN: chunk', chunkID)
          continue
        }
      }

      this.updateBounceBox()
      this.setSliderPosition()
      this.alignContainers()

      resolve()
    })
  },

  drawChunk (chunkData: ChunkData, chunkID: number): void {
    let y = topMargin
    let pathId = -1

    const trackContainer = new PIXI.Container()
    const graphGraphics = new PIXI.Graphics()
    const readGraphics = new PIXI.Graphics()

    const tracks = store.getters['chunkStore/getVisibleTracks']
    const graphTracks = tracks.graphTracks
    const readTracks = tracks.readTracks
    const vcfTracks = tracks.vcfTracks

    // If there are no tracks to draw, we show the placeholder
    if (graphTracks.length === 0 && readTracks.length === 0 && vcfTracks.length === 0) {
      // Show panto placeholder
      store.commit('graphStore/setPlaceholderVisible', true)
    } else {
      // Hide panto placeholder
      store.commit('graphStore/setPlaceholderVisible', false)
    }

    // If graphTracks is empty here, it's because the user disabled all graphTracks manually
    // In that case, we still draw the first path in the list as a placeholder to prevent any bug
    // TODO: this is a hack, we should not draw anything if there are no graphTracks to draw
    if (graphTracks.length === 0) {
      graphGraphics.alpha = 0
      graphTracks.push([...store.state.chunkStore.graphTracks.keys()][0])
    }

    // NOTE:
    // Shapes will be drawn relative to the chunk coordinates, and the whole container is moved
    // at the end of this function instead of the individual shapes to containerX

    const containerX = this.getXForBin(chunkData.firstBin, chunkID) + store.state.metaStore.metaContainerWidth

    for (const trackName of graphTracks) {
      pathId = pathId + 1

      if (!(trackName in chunkData.tracks)) {
        y = y + cellHeight
        continue
      }
      const path = chunkData.tracks[trackName]
      y = y + cellHeight

      for (let i = 0; i < chunkData.xoffsets.length; i++) {
        // We do NOT draw it at firstBin, but only at i + offset and then move the whole graphics
        const bin = chunkData.firstBin + i
        let binColumn = bin
        let columnOfFirstBinInChunk = chunkData.firstBin
        if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
          binColumn += chunkData.xoffsets[i]
          columnOfFirstBinInChunk += chunkData.xoffsets[0]
        }
        let xInChunk
        if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
          xInChunk = i * cellWidthMargin
        } else {
          xInChunk = (i + chunkData.xoffsets[i] - chunkData.xoffsets[0]) * cellWidthMargin
        }
        const posInChunk = i + 1
        let metaDataColor = {}

        // -------------------------
        // Draw links
        // -------------------------
        // TODO resolve the hack: path.links - why does it occur?
        if (store.state.metaStore.drawLinks && path.links && path.links[i] && path.links[i].length) {
          // let outgoing = 0
          // let incoming = 0

          for (let k = 0; k < path.links[i].length; k++) {
            const link = path.links[i][k]
            let globalColumn = 0
            let color

            if (link.length < 3) {
              if (store.state.metaStore.denseView) {
                globalColumn = binColumn
              } else {
                globalColumn = this.getColumnAndStoreColumnInfo(bin, binColumn, link, true)
              }
              const x = (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin
              this.drawTooManyLinksSymbol(graphGraphics, x, y)
              continue
            }

            const neighborLink = (Math.abs(Math.abs(link[2]) - Math.abs(link[1])) === 1)
            if (Math.abs(link[1]) - (chunkData.firstBin - 1) === posInChunk) {
              // Outgoing link
              if (store.state.metaStore.denseView) {
                globalColumn = binColumn
              } else {
                globalColumn = this.getColumnAndStoreColumnInfo(bin, binColumn, link, !neighborLink)
              }
              // outgoing++

              // store link info for drawing them later
              if (store.state.metaStore.drawLinks) {
                color = this.getColorAndStoreLinkInfo(link, false, globalColumn, pathId)
              }

              // Draw the outgoing links (if not denseView)
              const x = (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin
              if (!store.state.metaStore.denseView) {
                if (link[1] < 0) {
                  if (neighborLink) {
                    this.drawNeighborLeftArrow(graphGraphics, x, y)
                  } else {
                    this.drawOutgoingLinkArrow(graphGraphics, x, y, color)
                  }
                } else {
                  if (neighborLink) {
                    this.drawNeighborRightArrow(graphGraphics, x, y)
                  } else {
                    this.drawOutgoingLinkArrow(graphGraphics, x, y, color)
                  }
                }

                if (link[3] > 1) {
                  // print number of times (=link[3]) the link is traversed consecutively on top of the cell:
                  const text = new PIXI.Text(link[3] > 9 ? '+' : link[3],
                    Config.getGenomeNameTextStyle()).setTransform(x + cellMargin, y)
                  text.resolution = window.devicePixelRatio * 2 || 1
                  graphGraphics.addChild(text)
                  linkTextGroup.push(text) // GPU memory management
                }
              }
            } else if (Math.abs(link[2]) - (chunkData.firstBin - 1) === posInChunk && !neighborLink) {
              // Incoming link
              if (store.state.metaStore.denseView) {
                globalColumn = binColumn
              } else {
                globalColumn = this.getColumnAndStoreColumnInfo(bin, binColumn, link, true)
              }
              // incoming++

              // store link info for drawing them later
              if (store.state.metaStore.drawLinks) {
                color = this.getColorAndStoreLinkInfo(link, true, globalColumn, pathId)
              }

              // Draw the incoming links (if not denseView)
              const x = (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin
              if (!store.state.metaStore.denseView) {
                if (link[2] < 0) {
                  this.drawIncomingLinkArrowLeft(graphGraphics, x, y, color)
                } else {
                  this.drawIncomingLinkArrowRight(graphGraphics, x, y, color)
                }
              }
            }
          }
        }

        // -------------------------
        // Determine bin color
        // -------------------------
        if (store.state.metaStore.selectedMetadataToColor !== 'none') {
          // determine cell color by metadata
          const metadataValue = store.state.metaStore.metaData[trackName][store.state.metaStore.selectedMetadataToColor]
          metaDataColor = store.state.metaStore.colorLookupTable[store.state.metaStore.selectedMetadataToColor][metadataValue]
        }

        let cellColor = emptyColor
        if (path.covs[i] > 0) {
          if (store.state.metaStore.selectedMetadataToColor !== 'none' && metaDataColor !== '0xFFFFFF') {
            // color by metadata:
            cellColor = Number(metaDataColor)
          } else if (store.state.metaStore.drawInversions && path.invs[i] > 0 &&
            store.state.metaStore.drawDuplications && path.covs[i] > 1) {
            // draw purple inv + dupl
            cellColor = invDuplColor
          } else if (store.state.metaStore.drawInversions && path.invs[i] > 0) {
            // draw inversions in a shade of red
            cellColor = Number((invColorPaletteScale(path.invs[i]) as unknown as Color).hex().replace('#', '0x'))
          } else if (store.state.metaStore.drawDuplications && path.covs[i] > 1) {
            // blue for duplicated bin sequences
            if (path.covs[i] >= 8) {
              cellColor = spacerColor
            } else {
              cellColor = Number(duplColorPaletteArray[Math.trunc(path.covs[i] - 1)].replace('#', '0x'))
            }
          } else {
            // default fill color gradient based on covs
            cellColor = Number((fillColorPaletteScale(path.covs[i]) as unknown as Color).hex().replace('#', '0x'))
          }
        }

        // store bin in columnInfo
        const columnInfo = store.state.metaStore.columnInfo[binColumn]
        if (columnInfo === undefined) {
          store.commit('metaStore/addColumnInfo', {
            column: binColumn,
            columnInfo: {
              type: 'bin',
              bin
            }
          })
        }

        // color markers
        if (path.markers && path.markers[i] && path.markers[i] > 0) {
          cellColor = Config.markerColor
        }

        // -------------------------
        // Draw the bands of the graph tracks
        // -------------------------
        if (path.genes && path.genes[i] && path.genes[i].length) {
          // draw the cell together with a gene band
          let inExon = false
          // and if it's in an exon
          if (path.genes[i][2].length) {
            for (let g = 0; g < path.genes[i][2].length; g++) {
              if (path.genes[i][2][g].length) {
                inExon = true
              }
            }
          }

          // draw upper band (coverage)
          graphGraphics.beginFill(cellColor)
          graphGraphics.drawRect(xInChunk, y, cellWidth, Math.floor((cellHeight - cellMargin) * Config.upperBandHeightRatio))
          graphGraphics.endFill()

          // darkened fillColor if within a gene or exon:
          if (inExon) {
            cellColor = exonColor
          } else {
            cellColor = geneColor
          }

          // darken whole genes on reverse strand
          if (path.genes[i][1][0] === 0) {
            cellColor = Number(chroma(cellColor).darken(1.4).hex().replace('#', '0x'))
          }

          // draw bottom band (gene presence)
          graphGraphics.beginFill(cellColor)
          graphGraphics.drawRect(xInChunk, y + Math.floor((cellHeight - cellMargin) * Config.upperBandHeightRatio),
            cellWidth,
            Math.floor((cellHeight - cellMargin) * (1 - Config.upperBandHeightRatio)))
          graphGraphics.endFill()
        } else if (cellColor !== emptyColor) {
          // draw the cell without a gene band if the cell color is not the background color
          graphGraphics.beginFill(cellColor)
          graphGraphics.drawRect(xInChunk, y, cellWidth, cellHeight - cellMargin)
          graphGraphics.endFill()
        }

        // -------------------------
        // Draw readTracks
        // -------------------------
        if (store.state.metaStore.readsInFiles && readTracks && pathId + 1 >= graphTracks.length) {
          let counter = 0
          for (const track of readTracks) {
            const readsRow = y + cellHeight * (1 + Config.blankRowsBetweenTrackTypes + counter)
            const cov = track.bins[i].cov

            let mappedVal
            if (cov <= 1) {
              mappedVal = cov * 2 / 15
            } else if (cov <= 20) {
              mappedVal = (17 * cov + 59) / 570
            } else if (cov <= 100) {
              mappedVal = (7 * cov + 1540) / 2400
            } else {
              mappedVal = 1
            }
            mappedVal = (-mappedVal + 1) * 0xFF // invert range, map to 0,255

            let readColor = emptyColor
            readColor = mappedVal << 16 | mappedVal << 8 | mappedVal

            let noEdits = true
            // draw alternative seq in reads ('edits') as lower bands with their heights relative to their frequency:
            if (track.bins[i].edit) {
              const edit = track.bins[i].edit
              if (edit !== undefined) {
                noEdits = false
                const altCov = edit.A + edit.C + edit.G + edit.T + edit.dels
                const altRatio = altCov / (cov + edit.total)
                const yLowerBandHeight = Math.ceil((cellHeight - cellMargin) * altRatio)
                let yInsHeight = 0
                if (edit.ins.length) {
                  const insRatio = edit.ins.length / (cov + edit.total)
                  yInsHeight = Math.ceil((cellHeight - cellMargin) * insRatio)
                }
                const yUpperBandHeight = cellHeight - cellMargin - yLowerBandHeight - yInsHeight

                // draw the non-alt upper band:
                readGraphics.beginFill(readColor)
                readGraphics.drawRect(
                  xInChunk,
                  readsRow,
                  cellWidth,
                  yUpperBandHeight)
                readGraphics.endFill()

                if (altCov > 0) {
                  readGraphics.beginFill(Config.editColor)
                  readGraphics.drawRect(
                    xInChunk,
                    readsRow + (cellHeight - cellMargin) - yLowerBandHeight,
                    cellWidth,
                    yLowerBandHeight)
                  readGraphics.endFill()
                }
                // draw rectangles for insertions on top of other edits
                if (edit.ins.length) {
                  readGraphics.beginFill(chroma(Config.editColor).darken(1.3).hex().replace('#', '0x'))
                  const path = [xInChunk, readsRow + yUpperBandHeight,
                    xInChunk + cellWidthMargin, readsRow + yUpperBandHeight,
                    xInChunk + (cellWidthMargin / 2), readsRow + yUpperBandHeight + yInsHeight
                  ]
                  readGraphics.drawPolygon(path)
                  readGraphics.endFill()
                }
              }
            } else if (track.bins[i].avg_edit_frc) {
              const frc = track.bins[i].avg_edit_frc
              if (frc !== undefined) {
                noEdits = false
                const yUpperBandHeight = Math.floor((cellHeight - cellMargin) * (1 - frc))
                const yLowerBandHeight = cellHeight - cellMargin - yUpperBandHeight

                // draw the non-alt upper band:
                readGraphics.beginFill(readColor)
                readGraphics.drawRect(
                  xInChunk,
                  readsRow,
                  cellWidth,
                  yUpperBandHeight)
                readGraphics.endFill()

                // draw the lower alt band:
                readGraphics.beginFill(Config.editColor)
                readGraphics.drawRect(
                  xInChunk,
                  readsRow + yUpperBandHeight,
                  cellWidth,
                  yLowerBandHeight)
                readGraphics.endFill()
              }
            }

            if (noEdits) {
              // there are no edits:
              readGraphics.beginFill(readColor)
              readGraphics.drawRect(
                xInChunk,
                readsRow,
                cellWidth,
                cellHeight - cellMargin)
              readGraphics.endFill()
            }

            counter++
          }
        }
      } // loop over columns on x-axis
    } // for each graph track

    // With a high cov_fraction threshold, it can happen that some columns are not drawn.
    // To include them in the columnInfo (needed to read start and end of current view for the slider on top),
    // we have to fill them completely and for simplicity, we just iterate all the columns of the chunk again
    const firstCol = (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) ? chunkData.firstBin : chunkData.firstCol
    const lastCol = (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) ? chunkData.lastBin : chunkData.lastCol
    let lastColInfo = firstCol
    for (let pos = firstCol; pos <= lastCol; ++pos) {
      if (store.state.metaStore.columnInfo[pos] === undefined) {
        store.commit('metaStore/addColumnInfo', {
          column: pos,
          columnInfo: {
            type: 'bin',
            lastColInfo
          }
        })
      } else {
        lastColInfo = pos
      }
    }

    // Draw VCF tracks
    const vcfGraphics = this.drawVCFTracks([chunkID])

    // Tracks container (set position and add)
    trackContainer.position.set(containerX, 0)

    // Add to groups
    trackContainerGroup.push(trackContainer)
    graphGraphicsGroup.push(graphGraphics)
    readGraphicsGroup.push(readGraphics)
    vcfGraphicsGroup.push(vcfGraphics)

    // Add graphics to tracks container
    trackContainer.addChild(graphGraphics)
    trackContainer.addChild(readGraphics)
    trackContainer.addChild(vcfGraphics)

    // Add tracks container to matrixViewport
    matrixViewport.addChild(trackContainer)
  },

  drawQTLTracks (): void {
    if (store.state.pantoStore.enabledQTLTracks.length === 0) return

    // Remove children in case of redraw (no stage reset (bounce-box))
    qtlContainer.removeChildren()

    const qtls: Record<number, QTLg> = store.state.pantoStore.enabledQTLTracks.reduce((acc: Record<number, QTLg>, entry: QTLg) => {
      acc[entry.qtlID] = entry
      return acc
    }, {})

    // Sort qtls by trait
    const sortedQTLs = Object.entries(qtls).sort((a, b) => a[1].trait.localeCompare(b[1].trait))

    // Iterate over sorted qtls and set qtl.y
    let index = 0
    for (const [qtlID, qtl] of sortedQTLs) {
      qtl.y = index * store.state.chunkStore.qtlCellHeight
      index++
    }

    // Iterate over cached chunks
    let chunkID: any
    let containerX = 0
    for (chunkID of Object.keys(store.state.chunkStore.cachedChunks)) {
      const chunk = store.state.chunkStore.cachedChunks[chunkID]
      if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
        containerX = store.state.metaStore.metaContainerWidth + ((chunk.firstBin + chunk.xoffsets[0]) * cellWidthMargin)
      } else {
        containerX = store.state.metaStore.metaContainerWidth + (chunk.firstBin * cellWidthMargin)
      }

      // Init graphics
      const qtlGraphics = new PIXI.Graphics()

      // Set position and add QTL container
      qtlGraphics.position.set(containerX, 0)
      qtlContainer.addChild(qtlGraphics)

      // Add to group
      qtlGraphicsGroup.push(qtlGraphics)

      // Get QTLs
      QTLService.getQTLIDsOnPaths(chunkID).then((qtlIDs: Array<Array<number>>) => {
        if (!qtlIDs) return
        for (let i = 0; i < chunk.xoffsets.length; i++) {
          let columnX
          if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
            columnX = i * cellWidthMargin
          } else {
            columnX = (i + chunk.xoffsets[i] - chunk.xoffsets[0]) * cellWidthMargin
          }

          // Iterate over QTLIDs and draw QTLs
          if (qtlIDs && qtlIDs[i]) { // TODO resolve this hack - why does it occur?
            for (let j = 0; j < qtlIDs[i].length; j++) {
              if (qtlIDs[i][j] in qtls) {
                const qtl = qtls[qtlIDs[i][j]]
                if (qtl) {
                  qtlGraphics.beginFill(qtl.color, 1)
                  qtlGraphics.drawRect(columnX, qtl.y, cellWidth, store.state.chunkStore.qtlCellHeight)
                  qtlGraphics.endFill()
                }
              }
            }
          }
        }
        // Draw QTL background
        this.drawQTLBackground()
      })
    }
  },

  drawQTLBackground (): void {
    const qtlBgHeight = qtlContainer.height + store.state.chunkStore.qtlCellHeight
    qtlBackground.clear()
    qtlBackground.beginFill(backgroundColor)
    qtlBackground.drawRect(0, 0, app.view.width, qtlBgHeight)
    qtlBackground.endFill()
    // Draw line at the bottom of the QTL container
    if (qtlContainer.height > 0) {
      qtlBackground.lineStyle(1, 0x000000)
      qtlBackground.moveTo(0, qtlBgHeight)
      qtlBackground.lineTo(app.view.width, qtlBgHeight)
    }
  },

  drawVCFTracks (chunks: Array<number>) {
    // return new Promise<PIXI.Graphics>((resolve) => {
    const vcfGraphics = new PIXI.Graphics()
    const tracks = store.getters['chunkStore/getVisibleTracks']
    const graphTracks = tracks.graphTracks
    const readTracks = tracks.readTracks
    const vcfTracks = tracks.vcfTracks
    const y = topMargin + cellHeight * (graphTracks.length + readTracks.length + Config.blankRowsBetweenTrackTypes * 2 + 1)

    // const promises = []
    for (const [idx, trackName] of vcfTracks.entries()) {
      // promises.push(DataProvider.checkAndLoadVCFTracks(trackName, chunks).then(() => {
      for (const chunk of chunks) {
        const chunkData = store.state.chunkStore.cachedChunks[chunk]
        // there is data to draw if trackName is in this chunk
        // if not, don't complain, track might have no data in this chunk file
        if (trackName in chunkData.vcfTracks) {
          const bins = chunkData.vcfTracks[trackName].b
          if (bins.length > 0) {
            let varColumn: vcfBinData
            for (varColumn of bins) {
              const binInChunk = varColumn.b - chunkData.firstBin
              let xInChunk
              if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
                xInChunk = binInChunk * cellWidthMargin
              } else {
                xInChunk = (binInChunk + chunkData.xoffsets[binInChunk] - chunkData.xoffsets[0]) * cellWidthMargin
              }

              // determine cell color based on (hom/het/missing) genotype
              let cellColor: chroma.Color = chroma(Config.vcfColor)
              let avg = 0
              let numVars = 0
              if (!store.state.chunkStore.vcfTracks.get(trackName).sum) {
                for (const variant of varColumn.v) {
                  if ('g' in variant && !String(variant.g).includes('.')) {
                    if (typeof variant.g === 'number') {
                      avg = avg + variant.g + 0.0001
                    } else if (typeof variant.g === 'string') {
                      const gts = String(variant.g).split('/')
                      if (variant.g === '0/0') avg = avg + 0.0001
                      else if (gts[0] === gts[1] && gts[0] !== '.') avg = avg + 2
                      else avg = avg + 1
                    }
                    numVars++
                  }
                }
                avg = avg / numVars
                if (avg >= 1.5) { // if half or more genotypes are homozygous
                  cellColor = chroma(cellColor).darken(2)
                } else if (avg < 1) {
                  cellColor = chroma(cellColor).brighten(2)
                }
              }

              if (store.state.chunkStore.vcfTracks.get(trackName).sum || avg > 0) {
                vcfGraphics.beginFill(cellColor.hex().replace('#', '0x'))
                vcfGraphics.drawRect(
                  xInChunk,
                  y + idx * cellHeight,
                  cellWidth,
                  cellHeight)
                vcfGraphics.endFill()
              }
            }
          }
        }
      }
    }

    return vcfGraphics
  },

  drawSequenceText (chunk: ChunkData) {
    if (store.state.chunkStore.binWidth !== 1) {
      return
    }

    let seqX = store.state.metaStore.metaContainerWidth + this.getXForBin(chunk.firstBin)
    const seqY = 0

    // Draw sequence background
    const background = new PIXI.Graphics()
    background.beginFill(0xFFFFFF)
    background.drawRect(
      seqX,
      seqY - 0.5 * Config.topMargin,
      chunk.nrCols * cellWidthMargin,
      cellHeight + Config.topMargin
    )
    background.endFill()
    sequenceContainer.addChild(background)
    sequenceBackgroundGroup.push(background)

    let i
    for (i = 0; i < chunk.xoffsets.length; i++) {
      seqX = store.state.metaStore.metaContainerWidth + this.getXForBin(chunk.firstBin + i)
      const text = new PIXI.Text(chunk.sequence[i], Config.getGenomeNameTextStyle())
      const textWidth = text.width
      const textHeight = text.height
      const cellCenterX = seqX + cellWidthMargin / 2
      const cellCenterY = seqY + cellHeight / 2
      text.setTransform(cellCenterX - textWidth / 2, cellCenterY - textHeight / 2)
      text.resolution = window.devicePixelRatio * 2 || 1
      sequenceContainer.addChild(text)
      sequenceTextGroup.push(text) // GPU memory management
    }
  },

  drawMetadata () {
    const metaGraphics = new PIXI.Graphics()
    const style = Config.getGenomeNameTextStyle()

    // Get visible tracks
    const visibleTracks = store.getters['chunkStore/getVisibleTracks']
    const tracks = visibleTracks.graphTracks.concat(
      visibleTracks.readTracks,
      visibleTracks.vcfTracks
    )

    const yTop = -Config.maxNrLinks * cellHeight
    const height = -yTop + tracks.length * cellHeight + topMargin + cellHeight
    const yPathnames = topMargin + cellHeight

    // Get active metadata categories without 'Name'
    const metaCategories = store.getters['metaStore/metaDataCategories'].filter((category: string) => category !== 'Name')

    // Calculate meta container width
    const metaContainerWidth = metaCategories.length * (Config.cellWidth + Config.cellMargin) + store.state.metaStore.metaTextWidth
    store.commit('metaStore/setMetaContainerWidth', Math.ceil(metaContainerWidth))

    // Draw background
    metaBackground.clear()
    metaBackground.beginFill(0xFFFFFF, 1)
    metaBackground.drawRect(0, yTop, store.state.metaStore.metaContainerWidth, height)
    metaBackground.endFill()
    metaContainer.addChild(metaBackground)

    // Draw metadata heatmaps
    let y = yPathnames
    const lookupTable = store.getters['metaStore/getLookupTable']
    for (const trackName of tracks) {
      if (trackName in store.state.metaStore.metaData) {
        const trackMetadata = store.state.metaStore.metaData[trackName]

        let x = 0
        for (const metaDataName of metaCategories) {
          if (lookupTable[metaDataName][trackMetadata[metaDataName]] !== Config.metaMissingColor) {
            metaGraphics.beginFill(lookupTable[metaDataName][trackMetadata[metaDataName]])
            metaGraphics.drawRect(x, y, Config.cellWidth, Config.cellWidth)
            metaGraphics.endFill()
          }

          // Even in dense view mode, we draw full-sized quadratic cells with dimensions cellHeight x cellHeight
          x = x + cellHeight
        }
      }

      // Draw path names
      const text = new PIXI.Text(trackName, style).setTransform(metaCategories.length * cellHeight, y)
      text.resolution = window.devicePixelRatio * 2 || 1
      metaContainer.addChild(text)
      metaTextGroup.push(text) // GPU memory management

      // Add symbol marking if variant track is summarized or not
      if (store.state.chunkStore.vcfTracks.has(trackName) && store.state.chunkStore.vcfTracks.get(trackName).sum) {
        const textMetric: PIXI.TextMetrics = PIXI.TextMetrics.measureText(trackName, style)
        metaGraphics.beginFill(Config.vcfColor)
        metaGraphics.drawCircle(
          metaCategories.length * cellHeight + textMetric.width + Config.cellWidth * 0.5,
          y + 0.5 * Config.cellHeight,
          Config.cellWidth * 0.8 / 2
        )
        metaGraphics.endFill()
      }

      y = y + cellHeight
    }

    // Draw sortingTools
    sortingTools = new SortingTools(metaContainer)

    // Add to group
    metaGraphicsGroup.push(metaGraphics)

    // Add to container
    metaContainer.addChild(metaGraphics)
  },

  drawArcLinks (graphics: PIXI.Graphics, startX: number, endX: number) {
    startX = startX * cellWidthMargin
    endX = endX * cellWidthMargin

    graphics.beginFill(0xFFFFFF, 0)
    graphics.lineStyle(1, 0x000000, 0.2)
    graphics.arc(Math.min(startX, endX) + Math.abs((endX - startX) / 2),
      topMargin + cellHeight - cellMargin * 2,
      Math.abs((endX - startX) / 2),
      Math.PI, 0)
    graphics.endFill()
  },

  drawArrow (graphics: PIXI.Graphics, startX: number, endX: number, color: number, linkHeight: number, outgoing = true, incoming = true) {
    const start = startX * cellWidthMargin
    const end = endX * cellWidthMargin

    let arrowHeight = -(linkHeight + 2) * cellHeight
    cellHeight -= cellMargin // reset at the end of the function
    const base = topMargin + 10
    // path variable contains the path in the [x1, y1, x2, y2, .. , xn, yn] format
    // with x being the horizontal axis and y being the vertical axis
    let path = []

    if (outgoing && incoming) {
      if (endX >= startX) {
        path = [start, base,
          start, arrowHeight,
          end + cellWidth, arrowHeight,
          end + cellWidth, base - cellHeight / 2,
          end + cellWidth / 2, base,
          end, base - cellHeight / 2,
          end, arrowHeight + cellHeight,
          start + cellWidth, arrowHeight + cellHeight,
          start + cellWidth, base]
      } else {
        path = [start + cellWidth, base,
          start + cellWidth, arrowHeight,
          end, arrowHeight,
          end, base - cellHeight / 2,
          end + cellWidth / 2, base,
          end + cellWidth, base - cellHeight / 2,
          end + cellWidth, arrowHeight + cellHeight,
          start, arrowHeight + cellHeight,
          start, base]
      }
      graphics.beginFill(color, 0.8)
    } else if (outgoing) {
      arrowHeight = base - cellHeight
      path = [start, base,
        start, arrowHeight,
        start + cellWidth / 2, arrowHeight - cellHeight / 2,
        start + cellWidth, arrowHeight,
        start + cellWidth, base]
      graphics.beginFill(color, 1)
    } else if (incoming) {
      arrowHeight = base - cellHeight - cellHeight / 2
      path = [end, base - cellHeight / 2,
        end, arrowHeight,
        end + cellWidth, arrowHeight,
        end + cellWidth, base - cellHeight / 2,
        end + cellWidth / 2, base]
      graphics.beginFill(color, 1)
    } else {
      path = [0, 0,
        2000, 0,
        2000, 6000,
        0, 6000]
      graphics.beginFill(0xfc0303, 1)
    }

    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()

    cellHeight += cellMargin
  },

  drawNeighborRightArrow (graphics: PIXI.Graphics, xstart: number, ystart: number) {
    cellHeight -= cellMargin
    const path = [xstart, ystart,
      xstart + cellWidth / 2, ystart,
      xstart + cellWidth, ystart + cellHeight / 2,
      xstart + cellWidth / 2, ystart + cellHeight,
      xstart, ystart + cellHeight]
    cellHeight += cellMargin

    graphics.beginFill(nextArrowColor, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawNeighborLeftArrow (graphics: PIXI.Graphics, xstart: number, ystart: number) {
    cellHeight -= cellMargin
    const path = [xstart + cellWidth, ystart,
      xstart + cellWidth / 2, ystart,
      xstart, ystart + cellHeight / 2,
      xstart + cellWidth / 2, ystart + cellHeight,
      xstart + cellWidth, ystart + cellHeight]
    cellHeight += cellMargin

    graphics.beginFill(nextArrowColor, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawOutgoingLinkArrow (graphics: PIXI.Graphics, xstart: number, ystart: number, color: number) {
    cellHeight -= cellMargin
    const path = [xstart + cellWidth / 2, ystart,
      xstart + cellWidth, ystart + cellHeight / 2,
      xstart + cellWidth, ystart + cellHeight,
      xstart, ystart + cellHeight,
      xstart, ystart + cellHeight / 2]
    cellHeight += cellMargin

    graphics.beginFill(color, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawIncomingLinkArrow (graphics: PIXI.Graphics, xstart: number, ystart: number, color: number) {
    cellHeight -= cellMargin
    const path = [xstart + 2 * cellWidth / 5, ystart,
      xstart + 2 * cellWidth / 5, ystart + cellHeight,
      xstart + 3 * cellWidth / 5, ystart + cellHeight,
      xstart + cellWidth, ystart + 2 * cellHeight / 3,
      xstart + 3 * cellWidth / 5, ystart + cellHeight / 3,
      xstart + 3 * cellWidth / 5, ystart]
    cellHeight += cellMargin

    graphics.beginFill(color, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawIncomingLinkArrowLeft (graphics: PIXI.Graphics, xstart: number, ystart: number, color: number) {
    cellHeight -= cellMargin
    const path = [xstart + 3 * cellWidth / 4, ystart + cellHeight / 4,
      xstart + cellWidth / 4, ystart + cellHeight / 2,
      xstart + 3 * cellWidth / 4, ystart + 3 * cellHeight / 4]
    cellHeight += cellMargin

    graphics.beginFill(color, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawIncomingLinkArrowRight (graphics: PIXI.Graphics, xstart: number, ystart: number, color: number) {
    cellHeight -= cellMargin
    const path = [
      xstart + cellWidth / 4, ystart + cellHeight / 4,
      xstart + 3 * cellWidth / 4, ystart + cellHeight / 2,
      xstart + cellWidth / 4, ystart + 3 * cellHeight / 4]
    cellHeight += cellMargin

    graphics.beginFill(color, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawTooManyLinksSymbol (graphics: PIXI.Graphics, xstart: number, ystart: number) {
    graphics.beginFill(Config.tooManyLinksColor, 1)
    graphics.drawCircle(
      xstart + cellWidth / 2,
      ystart + cellHeight / 2,
      cellWidth / 2)
    graphics.endFill()
  },

  drawSepLine (graphics: PIXI.Graphics, xstart: number, ystart: number) {
    cellHeight -= cellMargin
    const path = [
      xstart, ystart,
      xstart, ystart + cellHeight
    ]
    cellHeight += cellMargin
    graphics.beginFill(0x000000, 1)
    graphics.lineStyle(1)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawConnectedLinks (viewport: Viewport) {
    const graphics = new PIXI.Graphics()
    const firstBin = store.state.chunkStore.currentFirstBin
    const columnOfFirstBinInChunk = this.getColForBin(firstBin)
    // let columnOfFirstBinInChunk = firstBin
    // let xOffsetOfFirstBinInChunk
    // if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
    //   const fileidx = DataProvider.getFileIndexForBinPos(firstBin)
    //   xOffsetOfFirstBinInChunk = store.state.chunkStore.cachedChunks[fileidx].xoffsets[0]
    //   columnOfFirstBinInChunk += xOffsetOfFirstBinInChunk
    // }

    if (!store.state.metaStore.denseView) {
      let minHeight = 0

      // linkColumns store all the columns that are links, and not for neighboring links
      // sort and iterate (with that we avoid to iterate over columnInfo which contains mostly bins)
      store.state.metaStore.linkColumns.sort()

      for (let i = 0; i < store.state.metaStore.linkColumns.length; ++i) {
        const column = store.state.metaStore.linkColumns[i]
        const linkID = store.state.metaStore.columnInfo[column].linkId
        if (!(linkID in store.state.metaStore.linkInfo)) {
          // TODO: check why this happens
          console.warn('linkID not found', linkID, 'linkColumn of i', i, column, 'linkInfo', store.state.metaStore.linkInfo)
          continue
        }
        const linkInfo = store.state.metaStore.linkInfo[linkID]

        // do nothing for neighboring links
        if (Math.abs(Math.abs(linkInfo.toBin) - Math.abs(linkInfo.fromBin)) === 1) {
          continue
        }

        if (linkInfo.height === undefined) {
          // first occurrence of the link.
          // It gets a height by searching the minimum idx where linkHeight is false
          for (; minHeight < Config.maxNrLinks; ++minHeight) {
            if (!linkHeights[minHeight]) break
          }
          linkInfo.height = minHeight
          linkHeights[minHeight] = true
        } else if (linkInfo.connected && column === Math.max(linkInfo.fromColumn, linkInfo.toColumn)) {
          // second occurrence of the link, and link is 'visible' since both ends are in currently loaded chunks
          // link height should be freed again, and link is drawn
          linkHeights[linkInfo.height] = false

          // adapt minHeight to directly point to the lowest available height
          if (linkInfo.height < minHeight) {
            minHeight = linkInfo.height
          }

          if (linkInfo.height > store.state.metaStore.maxLinkHeight) {
            store.commit('metaStore/setMaxLinkHeight', linkInfo.height)
          }

          // draw link:
          this.drawArrow(graphics,
            linkInfo.fromColumn - columnOfFirstBinInChunk,
            linkInfo.toColumn - columnOfFirstBinInChunk,
            linkInfo.color, linkInfo.height, true, true)
        }
      }
    } else {
      // draw arc:
      Object.keys(store.state.metaStore.linkInfo).forEach((linkId) => {
        if (store.state.metaStore.linkInfo[linkId].connected) {
          this.drawArcLinks(graphics,
            Math.abs(store.state.metaStore.linkInfo[linkId].fromBin) - firstBin,
            Math.abs(store.state.metaStore.linkInfo[linkId].toBin) - firstBin)
        }
      })
    }

    // The arrows/arcs have been drawn at a low x pos and now the graphics container is moved
    // to the correct position, to avoid moving each individual shape
    const x = this.getXForBin(firstBin) + store.state.metaStore.metaContainerWidth
    graphics.position.set(x, 0)

    // TODO: do not forget to mention in the docs that we have a max nr link param, set to 10,000 currently

    viewport.addChild(graphics)
  },

  getColumnAndStoreColumnInfo (bin: number, column: number, link: Array<number>, noNeighborLink: boolean) {
    let linkId
    let direction = 'right'
    // for a bin with too_many_links, draw column to the right

    if (link.length > 1) {
      const outgoingLink = (bin === link[1])
      // if link is outgoing from a bin in rev orientation, or
      // if link is incoming to the bin in forward orientation,
      // link column goes left of the bin column, otherwise right
      if (outgoingLink && link[1] < 0) {
        direction = 'left'
      } else if (!outgoingLink && link[2] > 0) {
        direction = 'left'
      }
      linkId = link[0]
    }

    let columnObj
    do {
      if (direction === 'right') {
        column++
      } else {
        column--
      }
      columnObj = store.state.metaStore.columnInfo[column]
    }
    while (link.length > 2 && columnObj !== undefined && columnObj.linkId !== linkId)
    // if link.length <= 2, we iterate only once to increment or decrement column once

    if (columnObj === undefined) {
      store.commit('metaStore/addColumnInfo', {
        column,
        columnInfo: {
          type: 'link',
          linkId,
          bin
        }
      })
      if (noNeighborLink) {
        store.commit('metaStore/addLinkColumn', column)
      }
    }

    return column
  },

  getColorAndStoreLinkInfo (linkInfo: Array<number>, arrival: boolean, linkColumn: number, pathId: number) {
    let color
    const linkId = linkInfo[0]
    const fromBin = linkInfo[1]
    const toBin = linkInfo[2]

    // we'll use the link ID as key for indexing
    let storedLinkInfo = store.state.metaStore.linkInfo[linkId]

    // Notes:
    // - drawnTo positions are relative to the current chunk
    // - from/to are global positions
    // - offsets are global xoffsets

    // use stored information about a partially visited arrow...
    if (storedLinkInfo !== undefined) {
      color = storedLinkInfo.color

      if (arrival) {
        storedLinkInfo.toColumn = linkColumn
      } else {
        storedLinkInfo.fromColumn = linkColumn
      }

      if (!storedLinkInfo.paths.includes(pathId)) {
        storedLinkInfo.paths.push(pathId)
      }

      // only if from and to position of link has been seen, mark link for drawing:
      if ('toColumn' in storedLinkInfo && 'fromColumn' in storedLinkInfo) {
        storedLinkInfo.connected = true
      }
      store.commit('metaStore/addLinkInfo', { linkId, linkInfo: storedLinkInfo })
    } else {
      color = store.state.metaStore.denseView
        ? Config.arcColor
        : this.getNextLinkColor(linkId, arrival, Math.abs(toBin), Math.abs(fromBin))
      storedLinkInfo = {
        linkId,
        linkInfo: {
          color,
          fromBin, // can be negative
          toBin, // can be negative
          connected: false,
          // drawn: false,
          paths: []
        }
      }

      if (arrival) {
        storedLinkInfo.linkInfo.toColumn = linkColumn
      } else {
        storedLinkInfo.linkInfo.fromColumn = linkColumn
      }

      if (!storedLinkInfo.linkInfo.paths.includes(pathId)) {
        storedLinkInfo.linkInfo.paths.push(pathId)
      }

      store.commit('metaStore/addLinkInfo', storedLinkInfo)
    }

    return color
  },

  getNextLinkColor (linkId: number, arrival: boolean, upstream: number, downstream: number) {
    const zoomLevel = DataProvider.getZoomLevelObj(store.state.chunkStore.binWidth)

    switch (store.state.metaStore.selectedLinkType) {
      case 'distance':
        // TODO: why not just abs(upstream-downstream)? num_bins is irrelevant, no?
        if (arrival) {
          return linkColorPalette(upstream / zoomLevel.num_bins).hex().replace('#', '0x')
        } else {
          return linkColorPalette(downstream / zoomLevel.num_bins).hex().replace('#', '0x')
        }
      default:
        return chroma.brewer.Paired[linkId % 12].replace('#', '0x')
    }
  },

  getTrackNameByCoordinate (y: number) {
    const row = this.getRowForY(y)
    if (row < 0) {
      return ''
    }

    let trackName = ''
    const paths = store.getters['chunkStore/getAllVisibleTracks']
    if (row < paths.length) {
      trackName = paths[row]
    }

    return trackName
  },

  getBinInfoForRawX (x: number): BinInfo {
    let maxX
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      maxX = store.state.metaStore.metaContainerWidth + store.state.chunkStore.currentLastColumn * cellWidthMargin
    } else {
      maxX = store.state.metaStore.metaContainerWidth + store.state.chunkStore.currentLastBin * cellWidthMargin
    }

    if (x > maxX) {
      const binInfo: BinInfo = { type: 'none', binNumber: store.state.chunkStore.currentLastBin }
      return binInfo
    }
    return this.getBinInfoForColumn(this.getColForX(x))
  },

  getBinInfoForColumn (column: number): BinInfo {
    const binInfo: BinInfo = { type: 'none', binNumber: store.state.chunkStore.currentFirstColumn }
    if ((store.state.metaStore.drawLinks && !store.state.metaStore.denseView &&
      (column < store.state.chunkStore.currentFirstColumn || column > store.state.chunkStore.currentLastColumn)) ||
      ((!store.state.metaStore.drawLinks || store.state.metaStore.denseView) &&
      (column < store.state.chunkStore.currentFirstBin || column > store.state.chunkStore.currentLastBin))) {
      return binInfo
    }

    if (column in store.state.metaStore.columnInfo) {
      binInfo.binNumber = store.state.metaStore.columnInfo[column].bin
      binInfo.type = store.state.metaStore.columnInfo[column].type
    } else {
      // When does that case happen?
      console.warn('WARNING: attempt to get columnInfo from column', column,
        'currFirstCol:', store.state.chunkStore.currentFirstColumn, 'currFirstBin', store.state.chunkStore.currentFirstBin,
        'currLastCol:', store.state.chunkStore.currentLastColumn, 'currLastBin', store.state.chunkStore.currentLastBin, 'colInfo', store.state.metaStore.columnInfo)
    }
    return binInfo
  },

  sortByColumnGraph (column: number) {
    // Fills metaStore.sortingTableGraph for sorting graph paths
    for (const chunk of Object.values(store.state.chunkStore.cachedChunks as Record<string, ChunkData>)) {
      if ((store.state.metaStore.drawLinks && !store.state.metaStore.denseView &&
          column >= (chunk.firstBin + chunk.xoffsets[0]) && column <= chunk.lastCol) ||
        ((!store.state.metaStore.drawLinks || store.state.metaStore.denseView) &&
          column >= chunk.firstBin && column <= chunk.lastBin)) {
        if (store.state.metaStore.columnInfo[column].type === 'bin') {
          const relativeBinInChunk = store.state.metaStore.columnInfo[column].bin - chunk.firstBin
          // ------ retrieve sorting data for bins:
          for (const p of store.getters['chunkStore/getVisibleTracks'].graphTracks) {
            if (p in chunk.tracks) {
              Vue.set(store.state.metaStore.sortingTableGraph, p, chunk.tracks[p].covs[relativeBinInChunk])
            } else {
              Vue.set(store.state.metaStore.sortingTableGraph, p, 0)
            }
          }
        } else {
          // ------ retrieve sorting data for links:
          const linkId = store.state.metaStore.columnInfo[column].linkId
          const link = store.state.metaStore.linkInfo[linkId]

          const graphTracksKeys = store.getters['chunkStore/getVisibleTracks'].graphTracks
          for (let k = 0; k < graphTracksKeys.length; k++) {
            if (link.paths.includes(k)) {
              Vue.set(store.state.metaStore.sortingTableGraph, graphTracksKeys[k], link.paths.length)
            } else {
              Vue.set(store.state.metaStore.sortingTableGraph, graphTracksKeys[k], 0)
            }
          }
        }
        break
      }
    }
  },

  sortByColumnVCF (column: number) {
    // Fills metaData.sortingTableVCF as a basis to sort by genotype values
    for (const chunk of Object.values(store.state.chunkStore.cachedChunks as Record<string, ChunkData>)) {
      if ((store.state.metaStore.drawLinks && !store.state.metaStore.denseView &&
          column >= (chunk.firstBin + chunk.xoffsets[0]) && column <= chunk.lastCol) ||
        ((!store.state.metaStore.drawLinks || store.state.metaStore.denseView) &&
          column >= chunk.firstBin && column <= chunk.lastBin)) {
        const colInfo = this.getBinInfoForColumn(column) // store.state.metaStore.columnInfo[column]

        if (colInfo.type === 'bin') {
          for (const trackName of [...store.state.chunkStore.vcfTracks.keys()]) {
            if (trackName in chunk.vcfTracks) {
              let avg = 0
              let numVars = 0
              for (const varData of chunk.vcfTracks[trackName].b) {
                if (varData.b === colInfo.binNumber) {
                  if (store.state.chunkStore.vcfTracks.get(trackName).sum) {
                    // fake numbers for correct calculation below
                    avg = 1000000
                    numVars = 1
                  } else {
                    // Calc average since there can be multiple variants in that same bin
                    for (const variant of varData.v) {
                      if ('g' in variant && !String(variant.g).includes('.')) {
                        if (typeof variant.g === 'number') {
                          avg = avg + variant.g + 0.0001
                        } else if (typeof variant.g === 'string') {
                          const gts = String(variant.g).split('/')
                          if (variant.g === '0/0') avg = avg + 0.0001
                          else if (gts[0] === gts[1] && gts[0] !== '.') avg = avg + 2
                          else avg = avg + 1
                        }
                      }
                    }
                    numVars = varData.v.length
                  }
                  break
                }
              }
              if (numVars > 0) {
                Vue.set(store.state.metaStore.sortingTableVCF, trackName, avg / numVars)
              } else {
                Vue.set(store.state.metaStore.sortingTableVCF, trackName, -1000000)
              }
            } else {
              Vue.set(store.state.metaStore.sortingTableVCF, trackName, -1000000)
            }
          }
          break
        }
      }
    }
  },

  // getColumnForBin (bin: number, filename: string | null = null) {
  //   let col
  //   if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
  //     if (!filename) {
  //       const fileIndex = DataProvider.getFileIndexForBinPos(bin - 1)
  //       filename = DataProvider.getFileName(fileIndex)
  //     }

  //     const idx = bin - store.state.chunkStore.cachedChunks[filename].firstBin
  //     col = bin + store.state.chunkStore.cachedChunks[filename].xoffsets[idx]
  //   } else {
  //     col = bin
  //   }

  //   return col
  // },

  getRowForY (y: number) { // 0-based
    return Math.floor((y - topMargin - cellHeight) / cellHeight)
  },

  getColForX (x: number) {
    return Math.floor((x - store.state.metaStore.metaContainerWidth) / cellWidthMargin)
  },

  // Check if a cell has a gene (first gene only)
  cellHasGene (x: number, y: number) : boolean {
    if (x - matrixViewport.left < store.state.metaStore.metaContainerWidth) return false
    const col = this.getColForX(x)
    if (col <= 0) return false
    if (!this.columnInfoHasCol(col)) return false

    const row = this.getRowForY(y)

    if (store.state.metaStore.columnInfo[col]) {
      // look for the correct bin to search bin information
      let chunk
      const fileIdx = DataProvider.getFileIndexForBinPos(col)
      if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
        chunk = store.state.chunkStore.cachedChunks[fileIdx]
      } else {
        for (const c of Object.values(store.state.chunkStore.cachedChunks as Record<number, ChunkData>)) {
          if (col >= c.firstCol && col <= c.lastCol) {
            chunk = c
            break
          }
        }
      }
      if (!chunk) return false

      const i = store.state.metaStore.columnInfo[col].bin - chunk.firstBin
      const paths = store.getters['chunkStore/getVisibleTracks'].graphTracks
      const trackName = paths[row]

      if (typeof chunk.tracks[trackName] !== 'undefined' && chunk.tracks[trackName].genes !== 'undefined') {
        if (chunk.tracks[trackName].genes && chunk.tracks[trackName].genes[i] && chunk.tracks[trackName].genes[i].length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  },

  // Get gene name and strand (first gene only) -- used in GeneMenu component
  getGeneInfos (x: number, y: number) : {name: string, strand: string, track: string} {
    let name = ''
    let strand = ''
    let track = ''

    const col = this.getColForX(x)
    if (col <= 0 || !this.columnInfoHasCol(col)) {
      return {
        name,
        strand,
        track
      }
    }
    const row = this.getRowForY(y)

    // look for the correct bin to search bin information
    let chunk
    const fileIdx = DataProvider.getFileIndexForBinPos(col)
    if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
      chunk = store.state.chunkStore.cachedChunks[fileIdx]
    } else {
      for (const c of Object.values(store.state.chunkStore.cachedChunks as Record<number, ChunkData>)) {
        if (col >= c.firstCol && col <= c.lastCol) {
          chunk = c
          break
        }
      }
    }

    const paths = store.getters['chunkStore/getVisibleTracks'].graphTracks
    const trackName = paths[row]
    const i = store.state.metaStore.columnInfo[col].bin - chunk.firstBin

    if (chunk.tracks[trackName].genes && chunk.tracks[trackName].genes[i] && chunk.tracks[trackName].genes[i].length) {
      const gene = chunk.tracks[trackName].genes[i]
      for (let g = 0; g < gene[0].length; ++g) {
        name = gene[0][g]
        strand = gene[1][g] ? 'Forward strand' : 'Reverse strand'
        track = trackName.replace(/_chr\d{2}/, '')
      }
    }

    return {
      name,
      strand,
      track
    }
  },

  // Check if column is a 'link' column
  // Usage: ContextMenu, to show/hide 'Follow link' option
  isColumnLink (x: number): boolean {
    const col = this.getColForX(x)
    if (!this.columnInfoHasCol(col)) return false
    if (store.state.metaStore.columnInfo[col] && store.state.metaStore.columnInfo[col].type === 'link') {
      return true
    } else {
      return false
    }
  },

  // Follow link. Bound to right click menu
  followLink (x: number) {
    const col = this.getColForX(x)
    if (col < 0) return

    const link = store.state.metaStore.linkInfo[store.state.metaStore.columnInfo[col].linkId]

    // jump to other end of link !
    if (col === link.toColumn) {
      store.commit('graphStore/setLoading', true)
      this.loadAndDrawChunks(Math.abs(link.fromBin), true).then(() => {
        store.commit('graphStore/setLoading', false)
      }).catch((error) => {
        console.warn(error)
      })
    } else if (col === link.fromColumn) {
      store.commit('graphStore/setLoading', true)
      this.loadAndDrawChunks(Math.abs(link.toBin), true).then(() => {
        store.commit('graphStore/setLoading', false)
      }).catch((error) => {
        console.warn(error)
      })
    }
  },

  getCellInfo (x: number, y: number) {
    if (x - matrixViewport.left < store.state.metaStore.metaContainerWidth) return ''

    const col = this.getColForX(x)
    const row = this.getRowForY(y)
    if (row < 0) return ''

    const graphTracks = store.getters['chunkStore/getVisibleTracks'].graphTracks
    const readTracks = store.getters['chunkStore/getVisibleTracks'].readTracks
    const vcfTracks = store.getters['chunkStore/getVisibleTracks'].vcfTracks

    const numberTracks = graphTracks.length + readTracks.length + vcfTracks.length + 2 * Config.blankRowsBetweenTrackTypes
    let info = ''
    if (row >= numberTracks ||
      (store.state.metaStore.drawLinks && !store.state.metaStore.denseView &&
        col > store.state.chunkStore.currentLastColumn) ||
      ((!store.state.metaStore.drawLinks || store.state.metaStore.denseView) &&
        col > store.state.chunkStore.currentLastBin)) {
      return info
    }

    // -------------------------------
    // LINK column
    // -------------------------------
    if (store.state.metaStore.columnInfo[col] && store.state.metaStore.columnInfo[col].type === 'link') {
      const link = store.state.metaStore.linkInfo[store.state.metaStore.columnInfo[col].linkId]
      // return tooltip with link information
      const diff = Number(Math.abs(link.fromBin) - Math.abs(link.toBin))
      let updown
      if (diff > 0) {
        updown = ' downstream to pos '
      } else {
        updown = ' upstream to pos '
      }
      return '</br>Link Info</br>From pos ' +
        GeneralUtils.numberWithCommas(link.fromBin) +
        '</br>' + Math.abs(diff) + updown +
        GeneralUtils.numberWithCommas(link.toBin)
    }

    // -------------------------------
    // BIN column
    // -------------------------------
    // if we are here: column is obviously a bin (if denseView is activated, there are only bins)
    // look for the correct bin to search bin information
    let chunk
    let chunkID = DataProvider.getFileIndexForBinPos(col)
    if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
      chunk = store.state.chunkStore.cachedChunks[chunkID]
    } else {
      for (const c of Object.values(store.state.chunkStore.cachedChunks as Record<number, ChunkData>)) {
        if (col >= c.firstCol && col <= c.lastCol) {
          chunk = c
          chunkID = DataProvider.getFileIndexForBinPos(c.firstBin)
          break
        }
      }
    }

    if (!chunk) return ''
    if (!this.columnInfoHasCol(col)) return ''

    const i = store.state.metaStore.columnInfo[col].bin - chunk.firstBin
    if (row < graphTracks.length) {
      const trackName = graphTracks[row]
      if (!(trackName in chunk.tracks)) {
        return info
      }

      const cov = chunk.tracks[trackName].covs[i]
      const inv = chunk.tracks[trackName].invs[i]

      if (chunk.tracks[trackName].ranges && (cov > 0 || inv > 0)) {
        info += 'Pos: '
        for (let c = 0; c < Math.min(chunk.tracks[trackName].ranges[i].length, 3); ++c) {
          const idx = (c === 2 ? chunk.tracks[trackName].ranges[i].length - 1 : c)
          if (c > 0) {
            info += '; '
          }
          if (c === 2 && chunk.tracks[trackName].ranges[i].length > 3) {
            info += '..., '
          }
          if (chunk.tracks[trackName].ranges[i][idx][0] === 0) {
            info += GeneralUtils.numberWithCommas(chunk.tracks[trackName].ranges[i][idx][1])
          } else {
            info += GeneralUtils.numberWithCommas(chunk.tracks[trackName].ranges[i][idx][0]) + '-' +
              GeneralUtils.numberWithCommas(chunk.tracks[trackName].ranges[i][idx][1])
          }
        }
      }
      if (cov >= 0) {
        info += '</br>Cov.: ' + cov
      }
      if (inv) {
        info += '</br>Inv.: ' + inv
      }

      // -------------------------------
      // additional GENE information
      // -------------------------------
      if (chunk.tracks[trackName].genes && chunk.tracks[trackName].genes[i] && chunk.tracks[trackName].genes[i].length) {
        info += '</br>Gene(s):'
        const gene = chunk.tracks[trackName].genes[i]
        for (let g = 0; g < gene[0].length; ++g) {
          info += '</br>' + gene[0][g] + ', ' +
            (gene[1][g] ? 'forward strand' : 'reverse strand')
          if (store.state.chunkStore.binWidth <= Config.tooltipInfoSkip &&
            gene[2][g].length > 0) {
            info += (gene[2][g]
              ? ', exon' + (gene[2][g].length > 1 ? 's ' : ' ') +
              gene[2][g].join(',')
              : '')
          }
        }
      }

      // -------------------------------
      // additional MARKER information
      // -------------------------------
      if (chunk.tracks[trackName].markers && chunk.tracks[trackName].markers[i] && chunk.tracks[trackName].markers[i] > 0) {
        info += '</br>' + chunk.tracks[trackName].markers[i] + ' markers'
      }
    } else {
      const rowInReads = row - graphTracks.length - Config.blankRowsBetweenTrackTypes // 0-based
      if (rowInReads < 0) return '' // we are between track types

      if (rowInReads + 1 <= readTracks.length) {
        // -------------------------------
        // READ track
        // -------------------------------
        store.commit('chunkStore/setCurrentChunk', chunkID)
        if (readTracks.length > 0) {
          const cov = readTracks[rowInReads].bins[i].cov

          // Show also edits or edit fraction in readTracks, if present:
          if (store.state.chunkStore.binWidth === 1) {
            if (!('edit' in readTracks[rowInReads].bins[i])) {
              info += 'Cov: ' + cov
            } else {
              const edits: EditObject = readTracks[rowInReads].bins[i].edit as EditObject || {}
              info += 'Cov: ' + (cov + edits.total)
              let counter = 0
              if (edits.total) {
                const altPercent = Math.round(100 * edits.total / (edits.total + readTracks[rowInReads].bins[i].cov))
                info += '</br>' + altPercent + '% Alt: '
                if (edits.A) info += (counter++ > 0 ? ', ' : '') + 'A:' + edits.A
                if (edits.C) info += (counter++ > 0 ? ', ' : '') + 'C:' + edits.C
                if (edits.G) info += (counter++ > 0 ? ', ' : '') + 'G:' + edits.G
                if (edits.T) info += (counter++ > 0 ? ', ' : '') + 'T:' + edits.T
                if (edits.dels) info += (counter++ > 0 ? ', ' : '') + 'del:' + edits.dels
                if (edits.ins.length) info += (counter++ > 0 ? ', ' : '') + 'ins[#' + edits.ins.length + ']:' + edits.ins.join() // + '(' + String(edits.ins[0]).length + 'bp)'
              }
            }
          } else { // bin width !== 1
            // TODO: calc correct alt fraction if cov is <1
            info += 'Cov: ' + readTracks[rowInReads].bins[i].cov
            const frc = readTracks[rowInReads].bins[i].avg_edit_frc
            if (typeof frc !== 'undefined' && frc > 0) {
              info += '</br>' + Math.round(frc * 100) + '% Alt'
            }
          }
        }
      } else {
        // -------------------------------
        // VCF track
        // -------------------------------
        const rowInVcfTracks = row - graphTracks.length - readTracks.length - 2 * Config.blankRowsBetweenTrackTypes // 0-based
        if (rowInVcfTracks + 1 <= vcfTracks.length) {
          const vcfInfo = store.state.chunkStore.vcfTracks
          const bin = this.getBinInfoForColumn(col).binNumber
          const trackName = vcfTracks[rowInVcfTracks]
          if (trackName in chunk.vcfTracks) {
            // track has info in that chunk
            for (const varData of chunk.vcfTracks[trackName].b) {
              if (varData.b === bin) {
                if (vcfInfo.get(trackName).sum) { // summarized vcf info:
                  for (const [idx, variant] of varData.v.entries()) {
                    if (idx > 0) {
                      info += '</br></br>'
                    }
                    info += 'Pos: ' + vcfInfo.get(trackName).refpath + ':' + GeneralUtils.numberWithCommas(variant.p)
                    info += '</br>Ref/Alt: ' + variant.r + '/' + variant.a
                    info += '</br>Frc GTs: ' + variant.g_valid + ' (total: ' + GeneralUtils.numberWithCommas(vcfInfo.get(trackName).num_samples) + ')'
                    info += '</br>Frc Hom: ' + variant.g11
                    info += '</br>Frc Het: ' + variant.g01
                  }
                } else {
                  // TODO following only needed for missing genotypes. If they are excluded by backend, we can optimize here
                  let numNonMissingVars = 0
                  for (const [idx, variant] of varData.v.entries()) {
                    if (!String(variant.g).includes('.')) numNonMissingVars++
                  }
                  if (store.state.chunkStore.binWidth === 1 || numNonMissingVars <= Config.maxNumVariantsToShow) {
                    // single bp level, or only less than maxNumVariantsToShow variants:
                    for (const [idx, variant] of varData.v.entries()) {
                      if (!String(variant.g).includes('.')) {
                        if (idx > 0) {
                          info += '</br></br>'
                        }
                        info += 'Pos: ' + vcfInfo.get(trackName).refpath + ':' + GeneralUtils.numberWithCommas(variant.p)
                        info += '</br>Ref/Alt: ' + variant.r + '/' + variant.a
                        info += '</br>GT: ' + variant.g
                        if (variant.c) {
                          info += ' (covs ' + variant.c + ')'
                        }
                      }
                    }
                  } else { // print only num variants:
                    info += 'Num Variants: ' + numNonMissingVars
                  }
                }
              }
            }
          }
        }
      }
    }
    return info
  },

  columnInfoHasCol (col: number) {
    // the following happens only if the first columns in a chunk are
    // links. This is tricky to determine, so we prevent a tooltip in this case:
    if (col in store.state.metaStore.columnInfo) {
      return true
    } else {
      return false
    }
  },

  selectRegionX (selection: Selection) {
    // selection.left = ((selection.left - cellWidthMargin) / matrixViewport.scale.x) + matrixViewport.left
    // selection.right = ((selection.right - cellWidthMargin) / matrixViewport.scale.x) + matrixViewport.left

    // console.log('selection', selection.right, matrixViewport.left)
    const posLeft = (selection.left / matrixViewport.scale.x) + matrixViewport.left
    const posRight = (selection.right / matrixViewport.scale.x) + matrixViewport.left - cellWidthMargin
    // console.log(' ->', posRight)

    this.highlightRegionX(
      this.getColForX(posLeft),
      this.getColForX(posRight)
    )

    store.commit('pantoStore/setSelectedBins', {
      left: this.getBinInfoForRawX(posLeft).binNumber,
      right: this.getBinInfoForRawX(posRight).binNumber
    })

    if (selection.left > store.state.metaStore.metaContainerWidth) {
      store.dispatch('graphStore/setSelectionContextMenu', {
        enabled: true,
        positionX: selection.right + 30,
        positionY: selection.top + 10,
        rawCoords: new PIXI.Point(selection.left, selection.top)
      })
    }
  },

  selectRegion (selection: Selection) {
    selection.left = (selection.left / matrixViewport.scale.x) + matrixViewport.left
    selection.right = (selection.right / matrixViewport.scale.x) + matrixViewport.left
    selection.top = (selection.top / matrixViewport.scale.y) + (matrixViewport.top + topMargin + cellHeight)
    selection.bottom = (selection.bottom / matrixViewport.scale.y) + (matrixViewport.top + topMargin + (cellHeight * 2))

    this.highlightRegion(
      this.getColForX(selection.left),
      this.getRowForY(selection.top),
      this.getColForX(selection.right),
      this.getRowForY(selection.bottom)
    )
  },

  async createRegionBedfile (binStart: number, binEnd: number): Promise<{ bedfileStr: string, lengthMap: Record<string, { len: number, reasons: string }> }> {
    const lens: Record<string, { len: number, reasons: string }> = {}
    const bedfileStr: string = await Promise.all(store.getters['chunkStore/getVisibleTracks'].graphTracks.map(async (trackName: string) => {
      // Convert bin coords to bin1 coords
      const leftBin = (binStart - 1) * store.state.chunkStore.binWidth + 1
      const rightBin = binEnd * store.state.chunkStore.binWidth
      // Get genome coordinates of bins
      const posStart = await ApiQueryService.getGenomePosOfBin(trackName, leftBin)
      const posEnd = await ApiQueryService.getGenomePosOfBin(trackName, rightBin)
      console.log(trackName, posStart, posEnd)

      let reason = ''
      let len = -1
      if (posStart === -1) {
        reason += 'Start coord missing, '
      } else if (posEnd === -1) {
        reason += 'End coord missing, '
      } else if (posStart.length > 1) {
        reason += 'Start coord non-unique, '
      } else if (posEnd.length > 1) {
        reason += 'End coord non-unique, '
      } else if (posStart[0] > posEnd[0]) {
        reason += 'Inverted coords, '
      } else if (posStart[0] === posEnd[0]) {
        reason += 'No sequence, '
      } else {
        len = posEnd[0] - posStart[0] + 1
      }

      // Store length info in any case
      lens[trackName] = { len: len, reasons: reason }

      if (len !== -1) {
        return `${trackName}\t${posStart[0]}\t${posEnd[0]}`
      } else {
        return ''
      }
    })).then((results: string[]) =>
      results.filter(str => str !== '').join('\n')
    )

    return { bedfileStr, lengthMap: lens }
  },

  async createVCFList (binStart: number, binEnd: number) {
    const vcfTrackList: string[] = []
    const refs = new Set<string>()
    const path = 's3://' + Config.s3BucketName + '/' + process.env.VUE_APP_PROJECT_PATH + 'data/vcfs/'
    const vcfTracks = store.getters['chunkStore/getVisibleTracks'].vcfTracks
    const hasRefCoords = new Set<string>()
    const refFileStr: string[] = []

    for (const track of vcfTracks) {
      const ref = store.state.chunkStore.vcfTracks.get(track).refpath
      if (!refs.has(ref)) {
        refs.add(ref)

        // Check if there are coordinates for this reference path
        const leftBin = (binStart - 1) * store.state.chunkStore.binWidth + 1
        const rightBin = binEnd * store.state.chunkStore.binWidth
        const posStart = await ApiQueryService.getGenomePosOfBin(ref, leftBin)
        const posEnd = await ApiQueryService.getGenomePosOfBin(ref, rightBin)
        console.log(ref, posStart, posEnd)
        if (posStart !== -1 && posEnd !== -1) {
          if (refFileStr.length === 0) {
            refFileStr.push('ID,start,stop')
          }
          refFileStr.push(`${ref},${posStart[0]},${posEnd[0]}`)
          hasRefCoords.add(ref)
        }
      }

      // Only store vcf track if there are valid coords of its reference path
      if (hasRefCoords.has(ref)) {
        if (vcfTrackList.length === 0) {
          vcfTrackList.push('sample,reference,vcf,tbi')
        }
        vcfTrackList.push(`${track},${ref},${path}${track}.vcf.gz,${path}${track}.vcf.gz.tbi`)
      }
    }

    return { vcfFileStr: vcfTrackList.join('\n'), refFileStr: refFileStr.join('\n') }
  },

  getMetaColumnIndexForX (x: number) {
    if (x > store.state.metaStore.metaContainerWidth) {
      return null
    }
    const col = Math.floor(x / (Config.cellWidth + Config.cellMargin))
    let enabledCount = 0
    for (let i = 0; i < store.state.metaStore.metaDataCategories.length; i++) {
      const category = store.state.metaStore.metaDataCategories[i]
      if (store.state.pantoStore.enabledMetaCategories.includes(category)) {
        if (enabledCount === col) {
          return i
        }
        enabledCount++
      }
    }
    return null
  },

  getXForBin (bin: number, chunkID: number | null = null) {
    let col = bin
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      if (!chunkID) {
        chunkID = DataProvider.getFileIndexForBinPos(bin)
      }
      const relativePosInChunk = bin - store.state.chunkStore.cachedChunks[chunkID].firstBin
      col += store.state.chunkStore.cachedChunks[chunkID].xoffsets[relativePosInChunk]
    }
    return col * cellWidthMargin // + store.state.metaStore.metaContainerWidth
  },

  getColForBin (bin: number, chunkID: number | null = null) {
    let col = bin
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      if (!chunkID) {
        chunkID = DataProvider.getFileIndexForBinPos(bin)
      }
      if (!(chunkID in store.state.chunkStore.cachedChunks)) {
        console.log('chunk', chunkID, 'not in cachedChunks:', store.state.chunkStore.cachedChunks, '(currentLeftBin', store.state.chunkStore.currentFirstBin, 'currentRightBin', store.state.chunkStore.currentLastBin, 'bin:', bin, 'fileIdx of that bin:', DataProvider.getFileIndexForBinPos(bin), ')')
      } else {
        const relativePosInChunk = bin - store.state.chunkStore.cachedChunks[chunkID].firstBin
        col += store.state.chunkStore.cachedChunks[chunkID].xoffsets[relativePosInChunk]
      }
    }
    return col
  },

  getBinAtViewportLeft () {
    return this.getBinInfoForRawX(matrixViewport.left + store.state.metaStore.metaContainerWidth).binNumber // + store.state.metaStore.metaContainerWidth)
  },

  cleanupOnDatasetChange () {
    // TODO setSelectedSortOption below will trigger a redraw. Don't execute cleanup when no dataset is loaded. Checking trackMap is maybe not sufficient after dataset change?
    if (!store.state.chunkStore.trackMap) return // can occur before dataset loading

    store.commit('chunkStore/setZoomLevels', [])
    // store.commit('chunkStore/setBinWidth', null)

    store.commit('chunkStore/setTrackMap', new Map())
    store.commit('metaStore/setReadsInFiles', false)
    store.commit('chunkStore/setVCFTracks', new Map())

    // reset sort variables
    store.commit('metaStore/setSortingTableGraph', {})
    store.commit('metaStore/setSortingTableVCF', {})
    store.commit('metaStore/setSelectedSortOption', 'id')
    store.commit('metaStore/setSelectedSortOrder', 'asc')

    // reset meta data
    store.commit('metaStore/setMetaContainerWidth', 0)
    store.commit('metaStore/setMetaDataCategories', [])
    store.commit('metaStore/setSelectedMetadataToColor', 'none')

    this.cleanupChunks()
  },

  cleanupOnBinWidthChange () {
    this.cleanupChunks()
  },

  cleanupChunks () {
    store.commit('chunkStore/setCachedChunks', {})
    store.commit('chunkStore/setCachedBins', 0)
    store.commit('chunkStore/setGraphTracks', new Map())
    store.commit('chunkStore/setRawGraphTracks', new Map())
    store.commit('chunkStore/setReadTracks', {})
    store.commit('chunkStore/setQTLsInCachedChunks', new Map())

    // clear current loaded chunk position info
    store.commit('chunkStore/setCurrentFirstColumn', null)
    store.commit('chunkStore/setCurrentLastColumn', 0)
    store.commit('chunkStore/setCurrentFirstBin', null)
    store.commit('chunkStore/setCurrentLastBin', 0)

    // cleanup columnInfo
    store.commit('metaStore/setLinkInfo', {})
    store.commit('metaStore/setColumnInfo', {})
    store.commit('metaStore/setLinkColumns', [])
    store.commit('metaStore/setMaxLinkHeight', 0)
    linkHeights = []
  }
}

export default Graph
