import store from '@/store'
import { ZoomLevel } from '@/types/Types'

const SortingUtils = {
  byMetaData () {
    return function (a: any, b: any) {
      if (!(a[0] in store.state.metaStore.metaData)) {
        return -1
      }
      if (!(b[0] in store.state.metaStore.metaData)) {
        return 1
      }

      const val1 = store.state.metaStore.metaData[a[0]][store.state.metaStore.selectedSortOption]
      const val2 = store.state.metaStore.metaData[b[0]][store.state.metaStore.selectedSortOption]

      if (!val1) return -1
      else if (!val2) return 1
      else {
        if (typeof val1 === 'number' && typeof val2 === 'number') {
          return val1 - val2
        } else if (typeof val1 === 'string' && typeof val2 === 'string') {
          return val1.localeCompare(val2)
        } else {
          return val1 < val2 ? -1 : (val1 > val2 ? 1 : 0)
        }
      }
    }
  },

  byColumn (type: string) {
    return function (a: any, b: any) {
      if (type === 'graph') {
        return store.state.metaStore.sortingTableGraph[b[0]] - store.state.metaStore.sortingTableGraph[a[0]]
      } // else if (type === 'vcf') {
      return store.state.metaStore.sortingTableVCF[b[0]] - store.state.metaStore.sortingTableVCF[a[0]]
    }
  },

  sortTracks (type: string) {
    const sortKey = store.state.metaStore.selectedSortOption
    let tracks
    if (type === 'graph') tracks = store.state.chunkStore.graphTracks
    else if (type === 'vcf') tracks = store.state.chunkStore.vcfTracks

    let sortedTracks = new Map()
    if (sortKey === 'ID') {
      // -------- Default sorting --------
      if (type === 'graph') {
        sortedTracks = new Map([...tracks.entries()].sort((a, b) =>
          store.state.chunkStore.trackMap.get(a[0]) - store.state.chunkStore.trackMap.get(b[0])
        ))
      } else if (type === 'vcf') {
        sortedTracks = new Map([...tracks.entries()].sort((a, b) => {
          return a[1].idx - b[1].idx
        }))
      }
    } else if (sortKey === 'Name') {
      // -------- Alphabetical sorting --------
      sortedTracks = new Map([...tracks.entries()].sort((a, b) =>
        (a > b ? 1 : (a === b ? 0 : -1))
      ))
    } else if (sortKey === 'column' || sortKey === 'vcf') {
      // -------- Cov (type: graph) or variant (type: vcf) sorting --------
      sortedTracks = new Map([...tracks.entries()].sort(this.byColumn(type)))
    } else if (store.state.metaStore.metaDataCategories.includes(sortKey)) {
      // -------- Metadata sorting --------
      if (type === 'vcf' && sortKey === store.state.chunkStore.dataset + '-tree') {
        // Do not sort vcf tracks by chr-wide tree clustering
        return
      }
      sortedTracks = new Map([...tracks.entries()].sort(this.byMetaData()))
    }

    if (store.state.metaStore.selectedSortOrder === 'desc') {
      // -------- Reverse sorting --------
      sortedTracks = new Map([...sortedTracks].reverse())
    }

    if (type === 'graph') {
      store.commit('chunkStore/setGraphTracks', sortedTracks)
    } else if (type === 'vcf') {
      store.commit('chunkStore/setVCFTracks', sortedTracks)
    }
  },

  sortZoomLevels (zoomLevels: Array<ZoomLevel>) {
    zoomLevels.sort(function (a, b) {
      return a.level - b.level
    })
    return zoomLevels
  }
}

export default SortingUtils
