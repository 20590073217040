<template>
  <div class="view">
    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this pipeline?<br><br><strong>{{ dialogText }}</strong></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- AppBar -->
    <AppBar title="Pipeline" />
    <!-- Content -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <h1 class="text-h4 mb-4">Pipelines</h1>
      <hr class="wide">
      <template>
        <v-tabs>
          <!-- Tabs -->
          <v-tab @click="checkStatus">Status</v-tab>
          <v-tab>Upload vcfs</v-tab>
          <v-tab>Upload expression</v-tab>
          <v-tab>Graph Genotyping</v-tab>
          <v-tab>Update Graph</v-tab>
          <!-- Status -->
          <v-tab-item>
            <v-card flat class="mt-4">
              <v-card-text>
                <v-data-table
                  :headers="statusHeaders"
                  :items="pipelines"
                >
                  <template v-slot:item.status="{ item }">
                    <v-alert
                      class="mt-4"
                      outlined
                      text
                      :type="getStatusType(item.status)"
                    >
                      {{ item.status }}
                    </v-alert>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      v-if="item.pipeline === 'clustering' && item.status === state.success"
                      @click="showTree(item)"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-file-tree</v-icon>
                        Show Tree
                    </v-btn>
                    <v-btn
                      v-if="item.pipeline === 'clustering' && item.status === state.success"
                      @click="download(item, 'tree.dnd')"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-download</v-icon>
                        tree
                    </v-btn>
                    <v-btn
                      v-if="item.pipeline === 'seqexport' && item.status === state.success"
                      @click="download(item, 'fasta')"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-download</v-icon>
                        fasta
                    </v-btn>
                    <v-btn
                      v-if="item.pipeline === 'seqexport' && item.status === state.success"
                      @click="download(item, 'tar')"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-download</v-icon>
                        vcf
                    </v-btn>
                    <v-btn @click="openDeleteDialog(item)" dense small elevation="0" class="mr-2">
                      <v-icon>mdi-delete</v-icon>
                      Delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- VCF -->
          <v-tab-item>
            <v-card flat max-width="600px" class="mt-4">
              <v-card-text>
                <h2 class="text-h7 mb-6">Adds new variation tracks from a (multi-sample) vcf file into Pantograph</h2>
                <v-alert
                  dismissible
                  text
                  outlined
                  color="deep-orange"
                >
                  <h3>Requirements:</h3>
                  <br><strong><i>- Samplesheet (samplesheet.csv)</i></strong> with the following fields:
                  <br> . Column 1: vcf group name
                  <br> . Column 2: name of reference genome (must be included in the graph)
                  <br> . Column 3: path of vcf file on s3
                  <br><br>- <strong><i>Multi-sample vcf file(s) on s3</i></strong>
                </v-alert>
                <v-form>
                  <v-text-field
                    v-model="VCFPipelineName"
                    label="Specify pipeline name"
                    required
                    filled
                  ></v-text-field>
                  <v-file-input
                    v-model="VCFSamplesheet"
                    accept=".csv"
                    filled
                    label="Import samplesheet"
                    @change="onVCFSamplesheetChange"
                  ></v-file-input>
                  <v-btn
                    style="display: block;"
                    class="mt-4"
                    elevation="0"
                    :disabled="!VCFPipelineName || !isVCFSamplesheetValid"
                  >
                    Start upload
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- EXPRESSION-->
          <v-tab-item>
            <v-card flat max-width="600px" class="mt-4">
              <v-card-text>
                <h2 class="text-h7 mb-6">Adds new gene expression data into Pantograph</h2>
                <v-alert
                  dismissible
                  text
                  outlined
                  color="deep-orange"
                >
                  <h3>Requirements:</h3>
                  <br><strong><i>Folder on s3</i></strong> containing:
                  <br>- Gene count matrix (gene x BioSample) in subfolder <i>gene_count_data</i>
                  <br>- Genes in row with ID in column 1 (must have the same ID as in the graph)
                  <br>- BioSamples in columns with their IDs in a header line
                  <br>- Description of BioSamples in subfolder <i>metadata</i>
                  <br>- TSV file with mandatory BioSample column, plus at least one of these headers: Part, Treatment, Cultivar
                </v-alert>
                <v-form>
                  <v-text-field
                    v-model="expressionPipelineName"
                    label="Specify pipeline name"
                    required
                    filled
                  ></v-text-field>
                  <v-text-field
                    label="Specify folder on s3 (s3://<bucket>/...)"
                    required
                    filled
                    v-model="expressionFolderPath"
                  ></v-text-field>
                  <v-btn
                    class="mt-2"
                    elevation="0"
                    :disabled="!expressionPipelineName || !expressionFolderPath"
                  >
                    Start upload
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- GENO -->
          <v-tab-item>
            <v-card flat max-width="600px" class="mt-4">
              <v-card-text>
                <h2 class="text-h7 mb-6">Aligns fastq read sequences against the pangenome graph and calls variation</h2>
                <v-alert
                  dismissible
                  text
                  outlined
                  color="deep-orange"
                >
                  <h3>Requirements:</h3>
                  <br><strong><i>- Samplesheet (samplesheet.csv)</i></strong> with the following fields:
                  <br>. Column 1: accession ID
                  <br>. Column 2: vcf group name
                  <br>. Column 3: path to reads1 in fastq format on s3
                  <br>. Column 4: path to reads2 in fastq format on s3
                  <br><br><strong><i>- Reference genome path</i></strong> (must be contained in the graph)
                </v-alert>
                <v-form>
                  <v-text-field
                    v-model="genoPipelineName"
                    label="Specify pipeline name"
                    required
                    filled
                  ></v-text-field>
                  <v-select
                    :items="genoPaths"
                    label="Reference genome path"
                    filled
                  ></v-select>
                  <v-file-input
                    v-model="genoSamplesheet"
                    accept=".csv"
                    filled
                    label="Import samplesheet"
                    @change="onGenoSamplesheetChange"
                  ></v-file-input>
                  <v-btn
                    style="display: block;"
                    class="mt-4"
                    elevation="0"
                    :disabled="!genoPipelineName || !genoPaths"
                  >
                    Start upload
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </template>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppBar from '@/components/AppBar.vue'
import { CSVValidator } from '@/utils/CSVValidator'
import { PipelineRun } from '@/types/Types'
import { ApiQueryService } from '@/services/ApiQueryService'

@Component({
  components: {
    AppBar
  }
})
export default class Pipelines extends Vue {
  breadcrumbs = [
    {
      text: 'Graph',
      disabled: false,
      href: '/graph'
    },
    {
      text: 'Pipelines',
      disabled: true
    }
  ]

  deleteDialog = false
  dialogText = ''
  pipelineToDelete: PipelineRun | null = null

  // Pipelines
  pipelines: PipelineRun[] = []

  get runningPipelines () {
    return this.$store.state.pipelineStore.runningPipelines
  }

  // Status
  statusHeaders = [
    { text: 'Name', value: 'parameters.panregion' },
    { text: 'Pipeline', value: 'pipeline' },
    { text: 'Status', value: 'status' },
    { text: 'User', value: 'user' },
    { text: 'Start date', value: 'start_date' },
    { text: 'Action', value: 'action' }
  ]

  state = {
    success: 'SUCCEEDED',
    fail: 'FAILED',
    running: 'ACTIVE'
  }

  // VCF
  VCFPipelineName = ''
  VCFSamplesheet: File | null = null
  isVCFSamplesheetValid = false

  // Expression
  expressionPipelineName = ''
  expressionFolderPath = ''

  // Genotyping
  genoPipelineName = ''
  genoSamplesheet: File | null = null
  isGenoSamplesheetValid = false
  genoPaths = [
    's3://bucket1/genomes/GRCh38.p13.genome.fa',
    's3://bucket2/genomes/GRCh38.p13.genome.fa',
    's3://bucket3/genomes/GRCh38.p13.genome.fa'
  ]

  // ------------------------------------------------
  // Methods
  // ------------------------------------------------

  mounted () {
    this.checkStatus()
  }

  checkStatus () {
    ApiQueryService.checkAllPipelineStatus().then((response: PipelineRun[]) => {
      console.log('Pipelines:', response)
      if (this.pipelines) {
        this.pipelines = []
      }

      // fill statusItems for display in the table
      for (const run of response) {
        run.pipeline = run.pipeline.substring(run.pipeline.lastIndexOf('-') + 1)

        this.pipelines.push(run)

        // if (run.status === this.state.running) {
        //   this.$store.commit('pipelineStore/addRunningPipeline', run)
        // }

        // console.log('Pipeline Run:', run)
        // this.checkEffect(run)
      }

      // go through the pipelines and check effects for finished pipelines
    }).catch((error) => {
      console.error(error)
    })
  }

  showTree (pipeline: PipelineRun) {
    // Check if tree and metadata were produced
    const treeFolder = pipeline.parameters.data_folder + '/output/'
    // treeFolder = treeFolder.replace('s3://pantograph-traitology-data/SOY51/', '')
    ApiQueryService.listFiles(treeFolder)
      .catch((error) => {
        console.error(error)
      })
      .then((response: any) => {
        console.log('Tree folder listup:', response.files)
        if (response.files.includes(pipeline.parameters.panregion + '.tree.dnd')) {
          // &&            response.data.files.includes(pipeline.parameters.panregion + '.metadata.tsv')) {
          console.log('Tree found')

          // set output tree as the tree to automatically load
          this.$store.commit('pantoStore/setTreeToShow', treeFolder + pipeline.parameters.panregion + '.tree.dnd')

          // switch to graph view at the position stored in the pipeline yaml, and open up tree window ('showTree: true')
          this.$store.commit('chunkStore/setBinWidth', pipeline.parameters.bin_width)
          this.$store.commit('chunkStore/setDataset', pipeline.parameters.dataset)
          this.$router.push({ name: 'Graph', params: { dataset: pipeline.parameters.dataset as string, startBin: `${pipeline.parameters.start_bin}`, showTree: 'true' } })
        } else {
          alert('Tree and tree metadata from this pipeline not found')
        }
      })
  }

  download (pipeline: PipelineRun, type: string) {
    let file = pipeline.parameters.data_folder + '/output/' + pipeline.parameters.panregion + '.' + type
    if (pipeline.pipeline === 'seqexport') {
      file = (pipeline.parameters.data_folder as string)
        .replace('/seqexport/', '/export_' + (type === 'tar' ? 'vcf' : type) + '/') +
        '/output/' + pipeline.parameters.panregion + '.' + type + '.gz'
    }
    console.log((pipeline.parameters.data_folder as string).replace('/seqexport/', '/export_' + type + '/'))
    if (file.endsWith('.gz')) {
      ApiQueryService.getZippedFile(file).then((url) => {
        // const blob = new Blob([response.data], { type: 'application/octet-stream' })
        // const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', pipeline.parameters.panregion + '.' + type + '.gz')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((error) => {
        this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Download not possible. ' + error, type: 'error', duration: 10000, dismissible: true })
        console.error(error)
      })
    } else {
      ApiQueryService.getFile(file).then((response) => {
        const blob = new Blob([response])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', pipeline.parameters.panregion + '.' + type)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((error) => {
        this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Download not possible. ' + error, type: 'error', duration: 10000, dismissible: true })
        console.error(error)
      })
    }
  }

  onVCFSamplesheetChange () {
    this.validateVCFSamplesheet()
  }

  onGenoSamplesheetChange () {
    // this.validateGenoSamplesheet()
  }

  // validateGenoSamplesheet () {
  //   if (!this.genoSamplesheet) {
  //     return
  //   }

  //   CSVValidator.validateGenoSamplesheet(this.genoSamplesheet).then((result) => {
  //     if (result.isValid) {
  //       this.isGenoSamplesheetValid = true
  //     } else {
  //       this.isGenoSamplesheetValid = false
  //       alert('Invalid samplesheet: ' + result.errors)
  //     }
  //   }).catch((error) => {
  //     alert(error)
  //   })
  // }

  validateVCFSamplesheet () {
    if (!this.VCFSamplesheet) {
      return
    }

    CSVValidator.validateVCFSamplesheet(this.VCFSamplesheet).then((result) => {
      if (result.isValid) {
        this.isVCFSamplesheetValid = true
      } else {
        this.isVCFSamplesheetValid = false
        alert('Invalid samplesheet: ' + result.errors)
      }
    }).catch((error) => {
      alert(error)
    })
  }

  getStatusType (status: string): string {
    switch (status) {
      case 'Pending':
        return 'info'
      case 'Running':
        return 'warning'
      case this.state.success:
        return 'success'
      case this.state.fail:
        return 'error'
      default:
        return 'info'
    }
  }

  openDeleteDialog (pipeline: PipelineRun) {
    this.pipelineToDelete = pipeline
    if (pipeline.pipeline === 'clustering') {
      this.dialogText = 'This will also delete the tree and metadata files on s3:\n' + pipeline.parameters.data_folder
    } else if (pipeline.pipeline === 'seqexport') {
      this.dialogText = 'This will also delete the fasta and vcf files on s3:\n' + pipeline.parameters.data_folder
    }
    this.deleteDialog = true
  }

  confirmDelete () {
    if (!this.pipelineToDelete) {
      return
    }
    this.deletePipeline(this.pipelineToDelete)
    this.deleteDialog = false
  }

  deletePipeline (pipeline: PipelineRun): void {
    ApiQueryService.deletePipeline(pipeline.name)
      .then((response) => {
        console.log('Pipeline deleted:', response)
      })
      .catch((error) => {
        console.error(error)
      })

    const folder = pipeline.parameters.data_folder as string
    if (pipeline.pipeline === 'seqexport' || pipeline.pipeline === 'clustering') {
      ApiQueryService.deleteFolder(folder)
        .then((response) => {
          console.log('Pipeline data deleted on s3:', response)
        })
    }
    if (pipeline.pipeline === 'seqexport') {
      // Delete also the possible two output folders
      ApiQueryService.deleteFolder(folder.replace('/seqexport/', '/export_fasta/'))
        .then((response) => {
          console.log('Pipeline output deleted on s3:', response)
        })
      ApiQueryService.deleteFolder(folder.replace('/seqexport/', '/export_vcf/'))
        .then((response) => {
          console.log('Pipeline output deleted on s3:', response)
        })
    }

    // Remove pipeline from this.pipeline
    const index = this.pipelines.findIndex(p => p.name === pipeline.name)
    if (index !== -1) {
      this.pipelines.splice(index, 1)
    }

    // If contained, delete it also from runningPipelines
    this.$store.commit('pipelineStore/deleteRunningPipeline', pipeline.name)

    // TODO: delete tree metadata from global metadata :/
  }
}
</script>

<style scoped lang="scss">

</style>
